import { Injectable, inject } from '@angular/core';

import { OfflineModeService } from '../offline-mode/offline-mode.service';
import { DietsService } from './diets.service';
import { OfflineDietsService } from './offline.diets.service';
import { OnlineDietsService } from './online.diets.service';

@Injectable({ providedIn: 'root' })
export class DietsServiceBuilder {
  private offlineModeService = inject(OfflineModeService);
  private onlineDietsService = inject(OnlineDietsService);
  private offlineDietsService = inject(OfflineDietsService);

  getService(): DietsService {
    let isOffline = false;
    this.offlineModeService
      .isOffline()
      .subscribe((value) => {
        isOffline = value;
      })
      .unsubscribe();
    return isOffline ? this.offlineDietsService : this.onlineDietsService;
  }
}
