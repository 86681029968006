<win-sub-navigation
  [label]="'navigation.back-to-menus' | transloco"
  (navigate)="backToMenus()"
></win-sub-navigation>

@if (showConsumerInfo) {
  <win-consumer-info
    class="mat-body consumer-info"
    [consumer]="consumer || editedItem"
    [currentMenuDiets]="currentMenuDiets"
    [hide]="true"
    [showDiets]="true"
    [diets]="diets"
    [dietsScheduled]="dietsScheduled"
    [hasItemsSelected]="hasItemsSelected"
    [isOffline]="isOffline"
    [lang]="lang"
    [nonRegularTranslations]="nonRegularTranslations"
    [orders]="orders"
    [showApplyOrder]="true"
    [showDetail]="showConsumerInfoDetail"
    [showSearchMenu]="true"
    (applySearchFilter)="applySearchFilter($event)"
    (applyDefaultOrder)="applyDefaultOrder.emit($event)"
    (applyDiets)="applyDiets.emit($event)"
    (unSelectAll)="unSelectAll()"
    (applyTranslation)="
      applyTranslation.emit({ translation: $event, menu: menu })
    "
  ></win-consumer-info>
}

@if (currentMenuSource) {
  <div
    #source
    runScripts
    class="menu-source"
    [class.show-hidden]="showHidden"
    [class.show-vip]="editedItem?.vip || consumer?.vip || consumerUnauth?.vip"
    [currentConsumer]="editedItem || consumer"
    [currentOrders]="currentOrders"
    [currentOrdersRepeat]="currentOrdersRepeat"
    [deselectedEvent]="deselectedEvent"
    [innerHtml]="currentMenuSource | safe"
    [isManager]="isManager"
    [lang]="menuLang"
    [ngClass]="consumerAllergies"
    [maximumOrdersPerSection]="
      isManager && editedItem ? 0 : menu?.maximum_components
    "
    [quantityFieldIncrement]="quantityFieldIncrement"
    [selectedEvent]="selectedEvent"
    [showDate]="menu?.show_date"
    (contentInit)="contentInit()"
    (itemsSelected)="setItemsSelected()"
    (updateRepeatOrders)="updateOrders($event); clearRepeatOrders.emit()"
  ></div>
} @else {
  <div class="skeleton-wrapper">
    <ngx-skeleton-loader
      class="skeleton title"
      [theme]="loaderStyleTitle"
    ></ngx-skeleton-loader>
    <div class="dishes-block">
      <ngx-skeleton-loader
        class="skeleton name"
        [theme]="loaderStyleName"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        class="skeleton dish"
        [theme]="loaderStyleDish"
      ></ngx-skeleton-loader>
    </div>

    <ngx-skeleton-loader
      class="skeleton title"
      [theme]="loaderStyleTitle"
    ></ngx-skeleton-loader>
    <div class="dishes-block">
      <ngx-skeleton-loader
        class="skeleton name"
        [theme]="loaderStyleName"
      ></ngx-skeleton-loader>
      @for (i of array(5).fill(0); track i) {
        <ngx-skeleton-loader
          class="skeleton dish"
          [theme]="loaderStyleDish"
        ></ngx-skeleton-loader>
      }
    </div>

    <ngx-skeleton-loader
      class="skeleton title"
      [theme]="loaderStyleTitle"
    ></ngx-skeleton-loader>
    <div class="dishes-block">
      <ngx-skeleton-loader
        class="skeleton name"
        [theme]="loaderStyleName"
      ></ngx-skeleton-loader>
      @for (i of array(3).fill(0); track i) {
        <ngx-skeleton-loader
          class="skeleton dish"
          [theme]="loaderStyleDish"
        ></ngx-skeleton-loader>
      }
    </div>
  </div>
}

@if (showTime && orders?.length) {
  <win-order-date [item]="firstOrder || editedItem"></win-order-date>
}

<win-save-stripe class="save-orders-stripe">
  <win-save-and-go
    [class.invisible]="currentMenuSource && !prevMenu"
    [menu]="prevMenu"
    [disabled]="!currentMenuSource"
    [arrowForward]="false"
    [lang]="lang"
    [sameDay]="menu?.date === prevMenu?.date"
    (btnClicked)="saveOrders('prev')"
  ></win-save-and-go>
  <button
    mat-fab
    extended
    color="primary"
    [disabled]="!currentMenuSource || saveOrderLoading"
    (click)="saveOrders()"
  >
    @if (saveOrderLoading) {
      <mat-spinner
        class="loading-spinner"
        mode="indeterminate"
        [diameter]="24"
      ></mat-spinner>
    }
    {{ 'orders.save_order' | transloco }}
  </button>
  <win-save-and-go
    [class.invisible]="currentMenuSource && !nextMenu"
    [menu]="nextMenu"
    [disabled]="!currentMenuSource"
    [arrowForward]="true"
    [lang]="lang"
    [sameDay]="menu?.date === nextMenu?.date"
    (btnClicked)="saveOrders('next')"
  ></win-save-and-go>
</win-save-stripe>
