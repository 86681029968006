import { Injectable, inject } from '@angular/core';

import { OfflineModeService } from '../offline-mode/offline-mode.service';
import { AuthenticationService } from './authentication.service';
import { OfflineAuthenticationService } from './offline.authentication.service';
import { OnlineAuthenticationService } from './online.authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationServiceBuilder {
  private offlineModeService = inject(OfflineModeService);
  private onlineAuthenticationService = inject(OnlineAuthenticationService);
  private offlineAuthenticationService = inject(OfflineAuthenticationService);

  getService(): AuthenticationService {
    let isOffline = false;
    this.offlineModeService
      .isOffline()
      .subscribe((value) => {
        isOffline = value;
      })
      .unsubscribe();
    return isOffline
      ? this.offlineAuthenticationService
      : this.onlineAuthenticationService;
  }
}
