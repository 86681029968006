import { Component, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import {
  selectGlobalLanguage,
  selectShowConsumerInfo,
  selectShowConsumerInfoDetail,
} from 'src/app/redux/global/global.selectors';
import {
  selectCurrentMenu,
  selectPickedDate,
} from 'src/app/redux/menus/menus.selectors';
import { selectOfflineModeValue } from 'src/app/redux/offline-mode/offline-mode.selectors';
import {
  selectOrders,
  selectOrdersRouterExtras,
  selectOrdersWereFetched,
  selectRepeatOrdersManage,
} from 'src/app/redux/orders/orders.selectors';
import { selectQueryParams } from 'src/app/redux/router/router.selectors';
import {
  selectConsumer,
  selectDisabledRepeatOptions,
  selectIsManager,
  selectTerminal,
} from 'src/app/redux/user/user.selectors';
import { AsyncPipe } from '@angular/common';
import { ConfirmOrdersComponent } from './confirm-orders/confirm-orders.component';

@Component({
  selector: 'win-orders-complete',
  templateUrl: './orders-complete.container.html',
  styleUrls: ['./orders-complete.container.scss'],
  standalone: true,
  imports: [ConfirmOrdersComponent, AsyncPipe],
})
export class OrdersCompleteContainer {
  private store = inject(Store);

  backQueryParam$ = this.store.pipe(select(selectQueryParams)).pipe(
    map((params) => params?.back),
    filter((back) => !!back),
  );
  consumer$ = this.store.pipe(select(selectConsumer));
  globalLanguage$ = this.store.pipe(select(selectGlobalLanguage));
  isManager$ = this.store.pipe(select(selectIsManager));
  isOffline$ = this.store.pipe(select(selectOfflineModeValue));
  isTerminal$ = this.store.pipe(select(selectTerminal));
  managableItem$ = this.store.pipe(select(selectOrdersRouterExtras));
  menu$ = this.store.pipe(select(selectCurrentMenu));
  orders$ = this.store.pipe(select(selectOrders));
  ordersFetched$ = this.store.pipe(select(selectOrdersWereFetched));
  repeatOrders$ = this.store.pipe(select(selectRepeatOrdersManage));
  selectedDate$ = this.store.pipe(select(selectPickedDate));
  selectShowConsumerInfo$ = this.store.pipe(select(selectShowConsumerInfo));
  selectShowConsumerInfoDetail$ = this.store.pipe(
    select(selectShowConsumerInfoDetail),
  );
  showBackLink$ = this.store
    .pipe(select(selectCurrentMenu))
    .pipe(map((menu) => menu && !(menu?.has_orders && !menu?.can_order)));
  selectDisabledRepeatOptions$ = this.store.pipe(
    select(selectDisabledRepeatOptions),
  );
}
