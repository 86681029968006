import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Primitive } from 'src/app/shared/models/generics';
import { SafePipe } from '../../../pipes/safe.pipe';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'win-consumer-data-item',
  templateUrl: './consumer-data-item.component.html',
  styleUrls: ['./consumer-data-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIconModule, SafePipe],
})
export class ConsumerDataItemComponent {
  @Input() showLabel = true;
  @Input() label: Primitive;
  @Input() value: Primitive;
  @Input() valueOverwritten: Primitive;
}
