import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'win-simple-dialog',
  standalone: true,
  templateUrl: './simple-dialog.component.html',
  styleUrl: './simple-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatDialogModule, MatIconModule, TranslocoPipe],
})
export class SimpleDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      text: string;
      link: string;
      linkText: string;
      cancelable: boolean;
      warn: boolean;
      confirmIcon: string;
      confirmText: string;
      cancelText: string;
    },
  ) {}
}
