import { Injectable, inject } from '@angular/core';

import { OfflineModeService } from '../offline-mode/offline-mode.service';
import { MenusService } from './menus.service';
import { OfflineMenusService } from './offline.menus.service';
import { OnlineMenusService } from './online.menus.service';

@Injectable({ providedIn: 'root' })
export class MenusServiceBuilder {
  private offlineModeService = inject(OfflineModeService);
  private onlineMenusService = inject(OnlineMenusService);
  private offlineMenusService = inject(OfflineMenusService);

  getService(): MenusService {
    let isOffline = false;
    this.offlineModeService
      .isOffline()
      .subscribe((value) => {
        isOffline = value;
      })
      .unsubscribe();
    return isOffline ? this.offlineMenusService : this.onlineMenusService;
  }
}
