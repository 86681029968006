import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { redirectToOrders } from 'src/app/redux/menus/menus.actions';
import {
  cancelOrders,
  clearOrdersPage,
  repeatOrderChanged,
  setGeneralRepeatOrders,
} from 'src/app/redux/orders/orders.actions';
import { ConsumerInfoComponent } from 'src/app/shared/components/consumer-info/consumer-info.component';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import { SubNavigationComponent } from 'src/app/shared/components/sub-navigation/sub-navigation.component';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { ROUTER_BACK_NAVIGATION } from 'src/app/shared/constants/misc';
import { Consumer } from 'src/app/shared/models/consumers';
import { DietDetailShort } from 'src/app/shared/models/diets';
import { Menu } from 'src/app/shared/models/menus';
import {
  ManagableItem,
  UnknownManagableItem,
} from 'src/app/shared/models/misc';
import {
  Order,
  REPEAT_OPTIONS,
  RepeatOrder,
  RepeatOrderOptionData,
} from 'src/app/shared/models/orders';
import { Terminal } from 'src/app/shared/models/user';
import { RepeatOrderComponent } from './repeat-order/repeat-order.component';
import { SelectedOrderComponent } from './selected-order/selected-order.component';

@Component({
  selector: 'win-confirm-orders',
  templateUrl: './confirm-orders.component.html',
  styleUrls: ['./confirm-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ConsumerInfoComponent,
    DatePipe,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    NgxSkeletonLoaderModule,
    RepeatOrderComponent,
    SelectedOrderComponent,
    SubNavigationComponent,
    TranslocoPipe,
  ],
})
export class ConfirmOrdersComponent implements OnChanges {
  private activatedRouter = inject(ActivatedRoute);
  private router = inject(Router);
  private store = inject(Store);

  @Input() consumer: Consumer;
  @Input() disabledRepeatOptions: REPEAT_OPTIONS[] = [];
  @Input() isManager: boolean;
  @Input() isOffline: boolean;
  @Input() isTerminal: Terminal;
  @Input() lang: InterfaceLanguage;
  @Input() menu: Menu;
  @Input() orders: Order[];
  @Input() ordersFetched: boolean;
  @Input() repeatOrders: RepeatOrder[];
  @Input() showBackLink: boolean;
  @Input() goBackPage: 'accesses' | 'consumers' | 'orders';
  @Input() selectedDate: string;
  @Input() showConsumerInfo: boolean | Terminal | ManagableItem;
  @Input() showConsumerInfoDetail: boolean;
  @Input() editedItem: ManagableItem | UnknownManagableItem;

  dietsScheduled: string[];
  goBackTranslations = ROUTER_BACK_NAVIGATION;
  hideInactiveRepeatedOrders = true;
  highlightGlobalRepeat = false;
  menuDate: string;

  constructor(private dialog: MatDialog) {
    this.menuDate = this.activatedRouter.snapshot.params.date;
  }

  canCancel = false;
  datelessMenu = false;
  pulsateIcon = true;
  inactiveOrders: RepeatOrder[];
  ordersDict = {};
  repeatOrdersDict = {};
  repeatOrdersEquivalentToOrders = false;
  repeatOrdersEquivalentToOrdersIsRepeatedDailyFrom = false;
  repeatOrdersAllType = null;

  ngOnChanges(changes: SimpleChanges) {
    if ('menu' in changes && this.menu) {
      this.datelessMenu = !this.menu.show_date;
      this.canCancel = this.datelessMenu ? null : this.menu.can_cancel;
    }
    if (
      this.datelessMenu &&
      this.canCancel === null &&
      this.menu &&
      !!this.orders?.length &&
      ('menu' in changes || 'orders' in changes)
    ) {
      const canCancelAnytime =
        this.isManager && (!!this.editedItem || !!this.isTerminal);
      this.canCancel = canCancelAnytime
        ? true
        : new Date(this.menuDate) >= new Date(this.menu.can_cancel_orders_from);
    }
    if (
      this.dietsScheduled === undefined &&
      this.menu &&
      (this.consumer || this.editedItem) &&
      ('menu' in changes || 'consumer' in changes || 'editedItem' in changes)
    ) {
      this.determineWhetherDietsScheduled(
        this.consumer || this.editedItem,
        this.menu,
      );
    }
    if (this.orders.length > 0 && this.pulsateIcon) {
      setTimeout(() => {
        this.pulsateIcon = false;
      }, 2000);
    }

    if (
      ('repeatOrders' in changes && this.repeatOrders && this.orders) ||
      ('orders' in changes && this.orders && this.repeatOrders)
    ) {
      if (this.repeatOrders) {
        this.repeatOrdersDict = this.setRepeatOrdersDict(this.repeatOrders);
      }
      if (this.orders) {
        this.ordersDict = this.setRepeatOrdersDict(this.orders);
      }
      if (this.repeatOrdersDict && this.ordersDict) {
        this.setInactiveRepeatOrders();
      }
      if (this.repeatOrders?.length && this.orders?.length) {
        this.validateRepeatOrders();
      }
    }
  }

  determineWhetherDietsScheduled(
    targetObj: Consumer | ManagableItem | UnknownManagableItem | undefined,
    menu: Menu | undefined,
  ) {
    if (!this.dietsScheduled && targetObj && menu) {
      if (this.selectedDate && menu.apply_scheduled_diets[this.selectedDate]) {
        this.dietsScheduled =
          menu.apply_scheduled_diets[this.selectedDate].join(',') !==
          targetObj?.diets.join(',')
            ? menu.apply_scheduled_diets_detail[this.selectedDate]
            : null;
      } else {
        this.dietsScheduled =
          menu.apply_diets.join(',') !== targetObj?.diets.join(',')
            ? menu.apply_diets_detail
            : null;
      }
    }
  }
  toMenus(): void {
    this.store.dispatch(clearOrdersPage());
    if (this.goBackPage) {
      this.router.navigate([`manage`, this.goBackPage]);
    } else {
      this.addAnotherOrder();
    }
  }

  back(): void {
    this.store.dispatch(redirectToOrders({ complete: false }));
  }

  cancelOrder(): void {
    const dialogRef = this.dialog.open(SimpleDialogComponent, {
      width: '600px',
      maxWidth: '100vw',
      autoFocus: false,
      data: {
        title: 'orders.confirm.cancel-dialog.title',
        text: 'orders.confirm.cancel-dialog.text',
        confirmIcon: 'delete',
        confirmText: 'orders.confirm.cancel-dialog.confirm',
        cancelText: 'orders.confirm.cancel-dialog.cancel',
        cancelable: true,
        warn: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          cancelOrders({
            editedItem: this.editedItem as ManagableItem,
            orders: this.orders,
          }),
        );
      }
    });
  }

  repeatChanged(
    { data, url }: { data: RepeatOrderOptionData; url: string | null },
    order: Order,
  ): void {
    this.store.dispatch(
      repeatOrderChanged({
        repeatOrderOption: data,
        order,
        url,
        menu: this.menu.identifier,
      }),
    );
  }

  repeatChangedNotOrdered(
    { data, url }: { data: RepeatOrderOptionData; url: string | null },
    repeatOrder: RepeatOrder,
  ): void {
    this.store.dispatch(
      repeatOrderChanged({
        repeatOrderOption: data,
        repeatOrder,
        url,
        menu: this.menu.identifier,
      }),
    );
  }

  globalRepeatChanged(value: RepeatOrderOptionData): void {
    this.highlightGlobalRepeat = value.option !== REPEAT_OPTIONS.DO_NO_REPEAT;
    this.repeatOrdersEquivalentToOrders =
      value.option !== REPEAT_OPTIONS.DO_NO_REPEAT;
    switch (value.option) {
      case REPEAT_OPTIONS.WEEKDAYS:
        this.repeatOrdersAllType = 'repeat_one';
        break;
      case REPEAT_OPTIONS.DATES:
        this.repeatOrdersAllType = 'date_range';
        break;
      case REPEAT_OPTIONS.REPEAT_DAILY_FROM:
        this.repeatOrdersAllType = 'event';
        break;
      case REPEAT_OPTIONS.REPEAT_DAILY:
        this.repeatOrdersAllType = 'repeat';
        break;
      default:
        this.repeatOrdersAllType = null;
    }
    this.store.dispatch(
      setGeneralRepeatOrders({ repeatOrderOptionData: value }),
    );
  }

  getSelectedDiets(selectedDietsValue: DietDetailShort[]): string {
    let diets = '';
    selectedDietsValue.forEach((element, i) => {
      diets += i > 0 ? ', ' + element.diet_name : element.diet_name;
    });
    return diets || '-';
  }

  addAnotherOrder(): void {
    this.router.navigate([`menus`], {
      queryParams: this.activatedRouter.snapshot.queryParams,
    });
  }

  setRepeatOrdersDict(
    repeatedOrders: (Order | RepeatOrder)[],
  ): Record<string, Record<string, Record<string, Order | RepeatOrder>>> {
    const result = {};
    repeatedOrders.forEach((repeatOrder) => {
      // dateless menus have the menu_name set, so we use the section_level1_baselang as the base key, else it is ignored
      const base =
        ('menu_name' in repeatOrder && repeatOrder.menu_name) ||
        ('show_date' in repeatOrder && repeatOrder.show_date) ||
        ('menu_detail' in repeatOrder && repeatOrder.menu_detail?.name)
          ? repeatOrder.section_level1_baselang
          : '';
      if (!result[base]) {
        result[base] = {};
      }
      if (!result[base][repeatOrder.section_level2_baselang]) {
        result[base][repeatOrder.section_level2_baselang] = {};
      }
      if (
        !result[base][repeatOrder.section_level2_baselang][
          repeatOrder.section_level3_baselang
        ]
      ) {
        result[base][repeatOrder.section_level2_baselang][
          repeatOrder.section_level3_baselang
        ] = {};
      }
      result[base][repeatOrder.section_level2_baselang][
        repeatOrder.section_level3_baselang
      ][repeatOrder.item_baselang] = repeatOrder;
    });
    return result;
  }

  toggleInactiveRepeated(): void {
    this.hideInactiveRepeatedOrders = !this.hideInactiveRepeatedOrders;
  }

  repeatAllOrdersConsumer(alreadyActive: boolean): void {
    this.highlightGlobalRepeat = !alreadyActive;
    this.repeatOrdersEquivalentToOrders = !alreadyActive;
    const repeatOrderOptionData: RepeatOrderOptionData = alreadyActive
      ? {
          option: REPEAT_OPTIONS.DO_NO_REPEAT,
          payload: {
            url: undefined,
            id: undefined,
          },
        }
      : {
          option: REPEAT_OPTIONS.REPEAT_DAILY_FROM,
          payload: this.orders[0].date,
        };
    this.store.dispatch(
      setGeneralRepeatOrders({
        repeatOrderOptionData,
      }),
    );
  }

  setInactiveRepeatOrders(): void {
    this.inactiveOrders = this.repeatOrders.filter(
      (repeatOrder) =>
        !this.ordersDict[
          this.datelessMenu ? repeatOrder.section_level1_baselang : ''
        ]?.[repeatOrder.section_level2_baselang]?.[
          repeatOrder.section_level3_baselang
        ]?.[repeatOrder.item_baselang],
    );
  }

  validateRepeatOrders(): void {
    this.repeatOrdersEquivalentToOrders = this.orders.every(
      (order) =>
        this.repeatOrdersDict[
          this.datelessMenu ? order.section_level1_baselang : ''
        ]?.[order.section_level2_baselang]?.[order.section_level3_baselang]?.[
          order.item_baselang
        ],
    );

    if (this.repeatOrdersEquivalentToOrders) {
      const repeatOrderTypes = this.orders
        .map((order) => {
          const repeatOrder =
            this.repeatOrdersDict[
              this.datelessMenu ? order.section_level1_baselang : ''
            ]?.[order.section_level2_baselang]?.[
              order.section_level3_baselang
            ]?.[order.item_baselang];

          if (repeatOrder?.repeat_weekdays?.length) return 'repeat_one';
          if (repeatOrder?.repeat_daily) return 'repeat';
          if (repeatOrder?.repeat_dates?.length) return 'date_range';
          if (repeatOrder?.repeat_daily_from) return 'event';

          return null;
        })
        .filter((type) => type !== null); // Filter out nulls to only consider orders with matching repeat orders

      if (repeatOrderTypes.every((type) => type === repeatOrderTypes[0])) {
        this.repeatOrdersAllType = repeatOrderTypes[0];
      } else {
        this.repeatOrdersAllType = null; // Mixed types or no repeat orders equivalent to orders
      }
    } else {
      this.repeatOrdersAllType = null; // Not all orders have a matching repeat order
    }
    this.repeatOrdersEquivalentToOrdersIsRepeatedDailyFrom =
      this.repeatOrdersAllType === 'repeat' ||
      this.repeatOrdersAllType === 'event';
  }
}
