import { Location, AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectRedirectionUrl } from 'src/app/redux/authentication/authentication.selectors';
import { fetchAvailableReports } from 'src/app/redux/user/user.actions';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'win-redirect',
  templateUrl: './redirect.container.html',
  styleUrls: ['./redirect.container.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    MatButtonModule,
    MatProgressSpinnerModule,
    TranslocoPipe,
  ],
})
export class RedirectContainer {
  private location = inject(Location);
  private router = inject(Router);
  private store = inject(Store);

  currentError: { error: string; back: boolean };
  redirectionUrl$ = this.store.select(selectRedirectionUrl);

  constructor() {
    this.currentError = this.router.getCurrentNavigation()?.extras?.state?.error
      ? (this.router.getCurrentNavigation().extras?.state as {
          error: string;
          back: boolean;
        })
      : undefined;
  }

  navigate(): void {
    if (this.currentError.back) {
      this.location.back();
    } else {
      this.router.navigate(['']);
      // the reports are not fetched if you land on the redirect page directly
      this.store.dispatch(fetchAvailableReports());
    }
  }
}
