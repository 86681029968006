import { InterfaceLanguage } from '../constants/languages';
import { TypeVariants } from '../utils.functions';
import {
  AnyAccessType,
  Consumer,
  isConsumer,
  isManagableAccess,
} from './consumers';
import { DietDetailShort, CONSISTENCY_LEVELS, TEXTURE_LEVELS } from './diets';
import { MenuDetail } from './menus';
import { ManagableItem, ManagableItemParams, MenusParams } from './misc';
import { CustomData, OrganisationLocation, UnauthConsumer, User } from './user';

export interface OrderVariant {
  id?: number;
  variant_id: number;
  item: string;
  item_baselang: string;
  price: number;
}

export interface FetchOrdersParams extends MenusParams {
  menu: string | number;
  menuId: string | number;
  date: string;
  rfid: string;
  own: boolean;
  fetchRepeatOrders: boolean;
  manage: boolean;
  menu_name: string;
  mark_done: boolean;
}

export function orderToRepeatOrderData(order: Order): RepeatOrderData {
  const keysToDelete = [
    'menu',
    'date',
    'menudish_id',
    'menu_detail',
    'diets_detail',
    'consumer_detail',
    'created_at',
    'created_by',
    'created_by_detail',
    'created_by_type',
    'last_modified_at',
    'last_modified_by',
    'last_modified_by_name',
    'diets',
    'id',
    'is_active',
    'is_user',
    'organisation',
    'price',
    'service_done_time',
    'service_expedite',
    'service_expedite_reason',
    'service_expedite_time',
    'service_fired_time',
    'service_hidden',
    'service_ordered_time',
    'service_status',
    'type',
    'url',
    'vip',
  ];
  const result = Object.fromEntries(
    Object.entries(order).filter(([key]) => !keysToDelete.includes(key)),
  ) as RepeatOrderData;
  result.menu_name = order.menu_detail?.name;
  // for dated menus, section_level1 should be ignored
  if (order.menu_detail?.dates?.length) {
    delete result.section_level1;
    delete result.section_level1_baselang;
  }
  return result;
}

export function repeatOrderOptionsToPayload(
  repeatOptionData: RepeatOrderOptionData,
): RepeatOrderChangedData {
  switch (repeatOptionData.option) {
    case REPEAT_OPTIONS.REPEAT_DAILY:
      return {
        repeat_weekdays: [],
        repeat_dates: [],
        repeat_daily_from: null,
      };
    case REPEAT_OPTIONS.WEEKDAYS:
      return {
        repeat_weekdays: repeatOptionData.payload,
        repeat_dates: [],
        repeat_daily_from: null,
      };
    case REPEAT_OPTIONS.DATES:
      return {
        repeat_dates: repeatOptionData.payload,
        repeat_weekdays: [],
        repeat_daily_from: null,
      };
    case REPEAT_OPTIONS.REPEAT_DAILY_FROM:
      return {
        repeat_daily_from: repeatOptionData.payload,
        repeat_weekdays: [],
        repeat_dates: [],
      };
  }
  return {};
}

export function extractOrderItems(htmlElements: HTMLElement[]): {
  items: SetOrdersItem[];
} {
  return htmlElements.reduce(
    (acc: { items: SetOrdersItem[] }, { dataset }) => {
      const ids: string[] | string = dataset?.id?.includes(`,`)
        ? dataset?.id?.split(`,`)
        : dataset?.id;

      const names: string[] | string = dataset?.name?.includes(`|`)
        ? dataset?.name?.split(`|`)
        : dataset?.name;
      const namesBase: string[] | string = dataset?.nameBase?.includes(`|`)
        ? dataset?.nameBase?.split(`|`)
        : dataset?.nameBase;
      const variantGroups: Record<number, OrderVariant[]> = {};

      if (dataset?.variants) {
        const zip = (a, b) => a.map((k, i) => [k, b[i]]);
        const tempVariants = dataset?.variants?.includes(`||`)
          ? dataset?.variants?.split(`||`)
          : [dataset?.variants];
        const tempVariantsBase = dataset?.variantsBase?.includes(`||`)
          ? dataset?.variantsBase?.split(`||`)
          : [dataset?.variantsBase];

        zip(tempVariants, tempVariantsBase).forEach(
          ([variantsObj, variantsBaseObj]: [string, string]) => {
            const variants = variantsObj.split(`\\\\`);
            const variantsBase = variantsBaseObj.split(`\\\\`);
            variantGroups[parseInt(variants[0])] = zip(
              variants[1].split(`|`),
              variantsBase[1].split(`|`),
            ).map(([variant, variantBase]: [string, string]) => {
              const splitVariant = variant.split(`\\`);
              const itemDetails = splitVariant[1].split('[');
              return {
                variant_id: parseInt(splitVariant[0]),
                item: itemDetails[0],
                item_baselang: variantBase.split(`\\`)[1].split('[')[0],
                price:
                  itemDetails.length > 1 ? +itemDetails[1].slice(0, -1) : null,
              } as OrderVariant;
            });
          },
        );
      }

      const sorting: string[] | string = dataset?.sorting?.includes(`,`)
        ? dataset?.sorting.split(`,`)
        : dataset?.sorting;
      const sectionSorting: string[] | string =
        dataset?.sectionSorting?.includes(`,`)
          ? dataset?.sectionSorting.split(`,`)
          : dataset?.sectionSorting;

      if (!ids || !names) return acc;
      const sectionLevel1 = dataset?.separatorLevel1;
      const sectionLevel2 = dataset?.separatorLevel2;
      const sectionLevel3 = dataset?.separatorLevel3;
      const sectionLevel1Base = dataset?.separatorLevel1Base;
      const sectionLevel2Base = dataset?.separatorLevel2Base;
      const sectionLevel3Base = dataset?.separatorLevel3Base;
      const optionItem = dataset?.option;
      const showDetails = !!dataset?.showDetails;
      const setDescription = dataset?.setComment;
      const setConsistency = dataset?.setConsistency;
      const setTexture = dataset?.setTexture;
      const minQuantity = dataset?.minQuantity;
      const price = dataset?.price?.includes(`|`)
        ? dataset?.price.split(`|`)
        : dataset?.price;
      const productSheet: string[] | string = dataset?.productSheet?.includes(
        `,`,
      )
        ? dataset?.productSheet.split(`,`)
        : dataset?.productSheet;

      const elements: SetOrdersItem[] = Array.isArray(ids)
        ? ids.map((id: string, index: number) => {
            const item: SetOrdersItem = {
              menudish_id: +id,
              item: names[index],
              item_baselang: namesBase[index],
            };
            Object.assign(item, {
              section_level1: sectionLevel1,
              section_level2: sectionLevel2,
              section_level3: sectionLevel3,
              section_level1_baselang: sectionLevel1Base,
              section_level2_baselang: sectionLevel2Base,
              section_level3_baselang: sectionLevel3Base,
              productSheet: Array.isArray(productSheet)
                ? productSheet[index]
                : productSheet,
              item_sorting: Array.isArray(sorting) ? +sorting[index] : +sorting,
              section_sorting: Array.isArray(sectionSorting)
                ? +sectionSorting[index]
                : +sectionSorting,
              item_option: +optionItem,
              show_details: showDetails,
              price: Array.isArray(price) ? +price[index] : +price,
              all_variants: variantGroups[+id] || [],
              set_description: setDescription,
              set_consistency: +setConsistency,
              set_texture: +setTexture,
              min_quantity: +minQuantity,
            });
            return item;
          })
        : [
            {
              menudish_id: +ids,
              item: names as string,
              item_baselang: namesBase as string,
              section_level1: sectionLevel1,
              section_level2: sectionLevel2,
              section_level3: sectionLevel3,
              section_level1_baselang: sectionLevel1Base,
              section_level2_baselang: sectionLevel2Base,
              section_level3_baselang: sectionLevel3Base,
              productSheet: productSheet as string,
              item_sorting: +sorting,
              section_sorting: +sectionSorting,
              item_option: +optionItem,
              show_details: showDetails,
              price: +price,
              all_variants: variantGroups[+ids] || [],
              set_description: setDescription,
              set_consistency: +setConsistency,
              set_texture: +setTexture,
              min_quantity: +minQuantity,
            },
          ];

      acc.items.push(...elements);
      return acc;
    },
    { items: [] },
  );
}

export type Consistency = (typeof CONSISTENCY_LEVELS)[number];
export type Texture = (typeof TEXTURE_LEVELS)[number];

export interface OrderBase {
  consumer: number;
  created_at: string;
  created_by: number;
  description: string;
  id: number;
  item: string;
  item_baselang: string;
  item_sorting: number;
  item_option: number;
  last_modified_at: string;
  last_modified_by: number;
  location: OrganisationLocation;
  organisation: number;
  portion_size: number;
  consistency: Consistency;
  texture: Texture;
  quantity: number;
  section_level1?: string;
  section_level2?: string;
  section_level3?: string;
  section_level1_baselang?: string;
  section_level2_baselang?: string;
  section_level3_baselang?: string;
  section_sorting: number;
  url: string;
  variants: OrderVariant[];
}

export enum ServiceStatus {
  RESERVED = 1,
  ORDERED = 2,
  FIRED = 3,
  DONE = 4,
}

export interface Order extends OrderBase {
  all_variants?: OrderVariant[];
  date: string;
  menu: string;
  menu_detail: MenuDetail;
  menudish_id: number;
  diets_detail: DietDetailShort[];
  show_details?: boolean;
  productSheet: string;
  price: number;
  service_status: ServiceStatus;
  created_by_type: number;

  created_by_detail?: any;
  consumer_detail?: any;
}

export interface RepeatOrderCondensed {
  id?: number;
  repeat_daily: boolean;
  repeat_weekdays: number[];
  repeat_dates: string[];
  repeat_daily_from: string;
  url: string;
}

export interface RepeatOrder extends OrderBase {
  item_option: number;
  last_modified_by_name: string;
  menu_name: string;
  repeat: REPEAT_OPTIONS;
  repeat_daily: boolean;
  repeat_weekdays: number[];
  repeat_dates: string[];
  repeat_daily_from: string;
  default_order?: boolean;
}

export interface RepeatOrderData
  extends Omit<
    Order,
    | 'menu'
    | 'date'
    | 'menudish_id'
    | 'repeat'
    | 'menu_detail'
    | 'diets'
    | 'diets_detail'
    | 'show_details'
    | 'productSheet'
    | 'price'
    | 'service_status'
    | 'created_by_type'
  > {
  menu_name: string;
  repeat_daily: boolean;
  repeat_weekdays: number[];
  repeat_dates: string[];
  repeat_daily_from: string;
}

export interface SetOrdersItem {
  menudish_id: number;
  item: string;
  item_baselang: string;
  section_level1?: string;
  section_level2?: string;
  section_level3?: string;
  section_level1_baselang?: string;
  section_level2_baselang?: string;
  section_level3_baselang?: string;
  description?: string;
  quantity?: number;
  item_sorting?: number;
  section_sorting?: number;
  item_option?: number;
  show_details?: boolean;
  price?: number;
  variants?: OrderVariant[];
  all_variants?: OrderVariant[];
  set_description?: string;
  set_consistency?: number;
  set_texture?: number;
  min_quantity?: number;
  uniqueRepeatOrderId?: string; // used for RepeatOrder items
  productSheet?: string;
  default_order?: boolean;
}

export interface SaveOrderButtonState {
  save_order_spinner: boolean;
}

export interface ProductSheetLoadingState {
  productSheetLoading: boolean;
}

export interface SetOrders {
  date: string;
  diets: string[];
  items: SetOrdersItem[];
  created_by?: number;
  consumer?: number;
  own?: boolean;
}

export interface OfflineSetOrders {
  consumer: string;
  data: SetOrders;
  date: string;
  menu: string;
  offline: boolean;
  status: 'waiting';
  url: string;
}

export enum REPEAT_OPTIONS {
  DO_NO_REPEAT = 0,
  REPEAT_DAILY = 1,
  WEEKDAYS = 2,
  DATES = 3,
  REPEAT_DAILY_FROM = 4,
}

export type RepeatOrderOptionData = TypeVariants<
  REPEAT_OPTIONS,
  {
    [REPEAT_OPTIONS.DO_NO_REPEAT]: { url: string; id: number };
    [REPEAT_OPTIONS.REPEAT_DAILY]: never;
    [REPEAT_OPTIONS.WEEKDAYS]: number[];
    [REPEAT_OPTIONS.DATES]: string[];
    [REPEAT_OPTIONS.REPEAT_DAILY_FROM]: string;
  }
>;

export type GeneralRepeatOrdersData = RepeatOrderChangedData & {
  date: string;
  own?: boolean;
  consumer?: number;
  created_by?: number;
};

export type RepeatOrderDataKeys =
  | 'repeat_daily'
  | 'repeat_dates'
  | 'repeat_weekdays'
  | 'repeat_daily_from';

export type RepeatOrderChangedData = Partial<
  Pick<RepeatOrderData, RepeatOrderDataKeys>
>;

export interface ApplyDefaultOrderData {
  date: string;
  language: InterfaceLanguage;
  created_by?: number;
  consumer?: number;
}

interface ManagableOrderBase {
  menu_identifier: string;
  menu_name: string;
  menu_has_date: boolean;
  order_diets: string[];
  order_diets_detail: string[];
  date: string;
  name: string;
  ids: number[];
  location: number | null;
  type: number | null;
  created_at: string;
  last_modified_at: string;
  warning_deadline: boolean;
  warning_deadline_changes: boolean;
  has_date: boolean;
  custom_data: CustomData;
  diets: string[];
  diets_detail: string[];
  type_name: string;
  room: string;
  room_floor: string;
  department: string;
  portion_size?: number;
  allergies?: string[];
  intolerances?: string[];
  consistency?: Consistency;
  texture?: Texture;
  checkin?: string;
  checkout?: string;
  id?: number;
  url?: string;
  is_active: boolean;
  vip: boolean;
}

export interface ManagableOrderUser extends ManagableOrderBase {
  created_by: number;
  is_user: true;
}

export interface ManagableOrderConsumer extends ManagableOrderBase {
  consumer: number;
  is_user: false;
}

export type ManagableOrder = ManagableOrderUser | ManagableOrderConsumer;

export function isManagableOrder(item: ManagableItem): item is ManagableOrder {
  return !isManagableAccess(item) && 'is_user' in item;
}

export function getParamsFromManagableOrder(
  order: ManagableOrder,
): ManagableItemParams {
  return order.is_user === true
    ? { created_by: order.created_by }
    : { consumer: order.consumer };
}

/**
 * Converts DOMStringMap of orderable HTML element to SetOrdersItem
 * @param dataset DOMStringMap of orderable HTML element
 * @returns Partial SetOrdersItem object sufficient for matching orders
 */
export function domStringMapToOrder(
  dataset: DOMStringMap,
): Partial<SetOrdersItem> {
  const {
    name,
    separatorLevel1,
    separatorLevel2,
    separatorLevel3,
    sectionSorting,
    option,
  } = dataset;
  return {
    item: name,
    section_level1: separatorLevel1,
    section_level2: separatorLevel2,
    section_level3: separatorLevel3,
    section_sorting: +sectionSorting,
    item_option: +option,
  };
}

/**
 * Checks if an HTML element matches the order or repat order provided,
 * showDate === true refers to dated menus,
 * matchSectionSortingOption optionally matches section sorting and option as well
 */
export function isOrderMatchingElement(
  elem: Partial<SetOrdersItem>,
  order: Order | RepeatOrder,
  showDate: boolean,
  matchSectionSortingOption = false,
): boolean {
  const {
    item: name,
    section_level1: separatorLevel1,
    section_level2: separatorLevel2,
    section_level3: separatorLevel3,
    section_sorting: sectionSorting,
    item_option: option,
  } = elem;

  const isMatchingSectionSortingAndOption = (
    order: Order | RepeatOrder,
  ): boolean => {
    return (
      order.section_sorting === sectionSorting && order.item_option === option
    );
  };

  // ! for menu ordering (multi-component), it will match an item if at least one component matches the name
  return (
    name.split('|').includes(order.item) &&
    // if it is a dated menu, section level 1 is ignored, else it must be empty or the same
    (showDate ||
      (!separatorLevel1 && !order.section_level1) ||
      separatorLevel1 === order.section_level1) &&
    // section level 2 is empty or the same
    ((!separatorLevel2 && !order.section_level2) ||
      separatorLevel2 === order.section_level2) &&
    // section level 3 is empty or the same
    ((!separatorLevel3 && !order.section_level3) ||
      separatorLevel3 === order.section_level3) &&
    // optionally match section sorting and option
    (!matchSectionSortingOption || isMatchingSectionSortingAndOption(order))
  );
}

export interface OrderDescriptionsData {
  consumer: AnyAccessType | ManagableOrder;
  featureConsistency: boolean;
  featureTexture: boolean;
  isAgent: boolean;
  isManager: boolean;
  isOffline: boolean;
  lang: InterfaceLanguage;
  minQuantity: number;
  orders: Order[];
  quantityFieldIncrement: number;
  quantityFieldInput: boolean;
  showDescription: boolean;
  showOnlyPortionSize: boolean;
  showOnlyQuantity: boolean;
  showOnlyVariants: boolean;
  closeDescription: () => void;
  fetchProductSheet: ({ token }: { token: string }) => void;
  searchAutocomplete: ({ value }: { value: string }) => void;
}

export interface GroupedOrder {
  ids: number[];
  service_status: ServiceStatus;
  count: number;
  section: string;
  service: string;
}

export type GroupedOrders = Record<string, GroupedOrder>;

export interface OrderTicket {
  ids: number[];
  menudish_id: number[];
  service_status: ServiceStatus;
  section_sorting: number;
  item_option: number;
  section: string;
  service: string;
  created_at: string;
  created_type: number;
  last_modified_at: string;
  last_modified_by_name: string;
  menu_has_date: boolean;
  menu_name: string;
  menu_source: string;
  item_sorting: number;
  item: string[];
  description: string[];
  quantity: number[];
  portion_size: number[];
  texture: (Texture | null)[];
  consistency: (Consistency | null)[];
  count_orders: number;
  is_special_order: boolean;
  order_diets: string[];
  variants: string[];
  vip: boolean;
  is_user: boolean;
  is_active: boolean;
  custom_data: CustomData;
  has_birthday: boolean;
  name: string;
  room: string;
  room_floor: string;
  department: string;
  type_name: string;
  type: number;
  table_number: string;
  service_table_number: string;
  service_id: number;
  diets: string[];
}

export function ordersToOrderTickets(
  orders: Order[],
  date: string,
  unauthConsumer: UnauthConsumer,
  terminalConsumer: Consumer,
  item: ManagableItem,
  currentUser: User,
) {
  return orders.reduce(
    (acc, order) => {
      const target = unauthConsumer || terminalConsumer || item || currentUser;
      const service = order.menu_detail.show_date
        ? order.section_level2_baselang
        : order.section_level1_baselang;
      const section = order.menu_detail.show_date
        ? order.section_level3_baselang
        : order.section_level2_baselang;
      const key = `${order.section_sorting}-${order.item_option}-${order.section_sorting}-${service}-${section}`;
      if (!acc[key]) {
        acc[key] = {
          ids: [],
          menudish_id: [],
          service_status: order.service_status,
          section_sorting: order.section_sorting,
          item_option: order.item_option,
          section: section,
          service: service,
          created_at: order.created_at,
          created_type: order.created_by_type,
          last_modified_at: order.last_modified_at,
          last_modified_by_name: currentUser.name,
          menu_has_date: order.menu_detail.show_date,
          menu_name: order.menu_detail.name,
          menu_source: order.menu_detail.source,
          item_sorting: order.item_sorting,
          item: [],
          description: [],
          quantity: [],
          portion_size: [],
          texture: [],
          consistency: [],
          count_orders: 0,
          is_special_order: false,
          variants: [],
          order_diets: [],
          vip: target.vip,
          is_user:
            (!unauthConsumer && !terminalConsumer && (!item || item.is_user)) ||
            true,
          is_active: true,
          custom_data: target.custom_data,
          has_birthday:
            isConsumer(target) && target.birthday
              ? new Date(target.birthday).getMonth() ===
                  new Date(date).getMonth() &&
                new Date(target.birthday).getDate() === new Date(date).getDate()
              : false,
          name: target.name,
          room: isConsumer(target) ? target.room : '',
          room_floor: isConsumer(target) ? target.room_floor : '',
          department: target.department,
          type_name: target.type_name,
          type: target.type,
          diets:
            Array.isArray(target.diets_detail) &&
            typeof target.diets_detail[0] === 'string'
              ? target.diets_detail
              : target.diets_detail.map((d) => d.diet_name),
          table_number: isConsumer(target) ? target.table_number : '',
          service_table_number: null,
          service_id: null,
        };
      }
      acc[key].ids.push(order.id);
      acc[key].menudish_id.push(order.menudish_id);
      acc[key].item.push(order.item_baselang);
      acc[key].description.push(order.description);
      acc[key].quantity.push(order.quantity);
      acc[key].portion_size.push(order.portion_size);
      acc[key].texture.push(order.texture);
      acc[key].consistency.push(order.consistency);
      acc[key].count_orders += 1;
      acc[key].order_diets.push(
        order.diets_detail.map((diet) => diet.diet_name).join(', '),
      );
      acc[key].variants.push(
        order.variants.map((variant) => variant.item_baselang).join(', '),
      );
      acc[key].item_sorting = Math.min(
        acc[key].item_sorting,
        order.item_sorting,
      );
      return acc;
    },
    {} as Record<string, OrderTicket>,
  );
}
