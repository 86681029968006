import { Component, OnDestroy, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';

import { UtilsService } from '../../services/utils.service';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { FieldWithErrorsComponent } from '../field-with-errors/field-with-errors.component';
import { FormWithErrorsComponent } from '../form-with-errors/form-with-errors.component';

@Component({
  selector: 'win-verify-password',
  templateUrl: './verify-password.dialog.html',
  styleUrls: ['./verify-password.dialog.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    FormWithErrorsComponent,
    ReactiveFormsModule,
    FieldWithErrorsComponent,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose,
    TranslocoPipe,
  ],
})
export class VerifyPasswordDialog implements OnDestroy {
  data = inject(MAT_DIALOG_DATA);
  private utilsService = inject(UtilsService);

  formGroup = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(32),
    ]),
  });
  readonly formId = 'verify-password';

  verifyPassword(): void {
    if (!this.formGroup.valid) return;
    this.data?.verifyCallback?.({
      ...(this.formGroup.value as { password: string }),
      formId: this.formId,
    });
  }

  ngOnDestroy(): void {
    this.utilsService.enableAutofocus.next(true);
  }
}
