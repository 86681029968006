import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { PasswordValidators } from 'ngx-validators';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatButtonModule } from '@angular/material/button';
import { FieldWithErrorsComponent } from '../../../shared/components/field-with-errors/field-with-errors.component';
import { FormWithErrorsComponent } from '../../../shared/components/form-with-errors/form-with-errors.component';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'win-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: [
    '../../../shared/styles/authentication.scss',
    './confirm.component.scss',
  ],
  standalone: true,
  imports: [
    FieldWithErrorsComponent,
    FormWithErrorsComponent,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    TranslocoPipe,
  ],
})
export class ConfirmComponent implements OnInit {
  private fb = inject(FormBuilder);

  @Input() spinnerState: boolean;
  @Output() confirm = new EventEmitter<{
    new_password1: string;
    new_password2: string;
  }>();

  passwordsForm: FormGroup;

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    const passwordValidators = [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(32),
    ];
    this.passwordsForm = this.fb.group(
      {
        new_password1: ['', passwordValidators],
        new_password2: ['', passwordValidators],
      },
      {
        validators: [
          PasswordValidators.mismatchedPasswords(
            `new_password1`,
            `new_password2`,
          ),
        ],
      },
    );
  }

  onSubmit(): void {
    if (this.passwordsForm.valid) this.confirm.emit(this.passwordsForm.value);
  }
}
