export interface LoginForm {
  email: string;
  password: string;
}

export const SimpleAuthenticationIds = [
  `auth_rfid`,
  `auth_external_id`,
  `auth_uuid`,
] as const;

export interface SimpleAuthenticationParams {
  key: string;
  id: { key: string; value: string };
}

export interface RegistrationForm {
  first_name: string;
  last_name: string;
  password1: string;
  password2: string;
  email: string;
  language: string;
  terms: boolean;
}

export interface LoginState {
  isLogged: boolean;
}

export interface LoginButtonState {
  login_spinner: boolean;
}

export interface ResetButtonState {
  reset_spinner: boolean;
}

export interface RegisterButtonState {
  register_spinner: boolean;
}

export interface SetLoggedIntoTerminal {
  isLoggedIntoTerminal: boolean;
}

export interface LoginSuccess {
  access: string;
  refresh: string;
}

export interface ResetSuccess {
  access: string;
  refresh: string;
  detail: string;
}

export interface VerifySuccess extends LoginSuccess {
  detail: string;
}

export interface GenericName {
  name?: string;
  first_name?: string;
  last_name?: string;
}

export interface RoomLoginForm {
  rfid?: string;
  uuid?: string;
  external_id?: string;
}

export interface ConsumerNameTerminalLoginForm {
  room: number;
  name?: string;
  first_name?: string;
  last_name?: string;
}

export interface ConfirmPasswordResetForm {
  new_password1: string;
  new_password2: string;
  uid: string;
  token: string;
}

export interface EmailVerification {
  catch_all: boolean;
  did_you_mean: string;
  disposable: boolean;
  email: string;
  format_valid: boolean;
  free: boolean;
  id: number;
  last_lookup: string;
  mx_found: boolean;
  role: boolean;
  score: number;
  smtp_check: boolean;
}

export const emailVerificationErrorFields = ['disposable'];
export const urlRegexPattern =
  /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]+)*\/?(\?[^?#]+)?(#.+)?$/;

export interface PasswordVerificationForm {
  password: string;
  formId: string;
}
