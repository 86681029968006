import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { distinctUntilChanged } from 'rxjs';
import { ROUTER_BACK_NAVIGATION } from 'src/app/shared/constants/misc';
import { Consumer, ManagableAccess } from 'src/app/shared/models/consumers';
import { Menu } from 'src/app/shared/models/menus';
import {
  ManagableItem,
  UnknownManagableItem,
} from 'src/app/shared/models/misc';
import { ManagableOrder } from 'src/app/shared/models/orders';
import { SimpleConsumer, Terminal } from 'src/app/shared/models/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { SaveStripeComponent } from '../../../shared/components/save-stripe/save-stripe.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { SubNavigationComponent } from '../../../shared/components/sub-navigation/sub-navigation.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MenuComponent } from './menu/menu.component';
import { MenusCardsComponent } from './menus-cards/menus-cards.component';
import { ConsumerInfoComponent } from '../../../shared/components/consumer-info/consumer-info.component';
import { NgTemplateOutlet } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';

@Component({
  selector: 'win-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ConsumerInfoComponent,
    MenusCardsComponent,
    MenuComponent,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    NgTemplateOutlet,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    SaveStripeComponent,
    SubNavigationComponent,
    TranslocoPipe,
    MatButtonModule,
  ],
})
export class MenusComponent implements OnChanges, OnInit {
  private dateAdapter = inject<DateAdapter<Date>>(DateAdapter);
  private destroyRef = inject(DestroyRef);

  @Input() menus: Menu[];
  @Input() dailyMenus: Menu[];
  @Input() specialMenus: Menu[];
  @Input() isAdmin: boolean;
  @Input() isConsumer: boolean | SimpleConsumer;
  @Input() isKdsUser: boolean;
  @Input() isServiceUser: boolean;
  @Input() isTerminal: boolean | Terminal;
  @Input() isAgent: boolean;
  @Input() isManager: boolean;
  @Input() isOffline: boolean;
  @Input() isUnauth: boolean;
  @Input() fullName: string;
  @Input() item: ManagableAccess | ManagableOrder | UnknownManagableItem;
  @Input() offlineDate: string;
  @Input() lang: InterfaceLanguage;
  @Input() staffOrdertaking: boolean;
  @Input() showThirdMenuCard: boolean;
  @Input() goBackPage: 'accesses' | 'consumers' | 'orders';
  @Input() consumer: Consumer;
  @Input() showConsumerInfo: boolean | Terminal | ManagableItem;
  @Input() showConsumerInfoDetail: boolean;

  @Output() logout = new EventEmitter<void>();
  @Output() selectMenu = new EventEmitter<Menu>();
  @Output() selectMenuShowDatepicker = new EventEmitter<Menu>();
  @Output() goBack = new EventEmitter<void>();
  @Output() changeCheckoutDate = new EventEmitter<{
    data: { checkout: string };
    id: number;
    url: string;
  }>();

  minDate = new Date();
  initDate = new Date();
  isPastCheckout = false;
  checkoutDate = new FormControl('');
  small = window.innerWidth <= 400;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.small = window.innerWidth <= 400;
  }

  @ViewChild('menusRef', { read: ElementRef })
  menusRef: ElementRef;

  goBackTranslations = ROUTER_BACK_NAVIGATION;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      ('item' in changes && this.item && this.isManager) ||
      ('isManager' in changes && this.isManager && this.item)
    ) {
      this.isPastCheckout = this.checkIsPastCheckout();
    }
  }

  ngOnInit(): void {
    this.checkoutDate.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe((date) =>
        this.changeCheckoutDate.emit({
          data: {
            checkout: this.dateAdapter.format(new Date(date), 'yyyy-MM-dd'),
          },
          id: this.item.id,
          url: this.item.url,
        }),
      );
  }

  scrollToMenus(): void {
    (this.menusRef?.nativeElement as HTMLElement).scrollIntoView({
      behavior: 'smooth',
    });
  }

  checkIsPastCheckout(): boolean {
    const checkoutDate = this.item?.checkout;
    if (!checkoutDate) return undefined;
    const checkout = new Date(checkoutDate);

    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const offset = date.getTimezoneOffset();
    const today = new Date(date.getTime() - offset * 60 * 1000);
    const result = today.getTime() > checkout.getTime();
    if (result) {
      this.checkoutDate.patchValue(this.item?.checkout, { emitEvent: false });
    }
    return result;
  }
}
