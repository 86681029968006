import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterAction } from '@ngrx/router-store';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  retry,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { OfflineOrdersService } from 'src/app/shared/services/offline-mode/offline-orders.service';
import { OnlineOrdersService } from 'src/app/shared/services/orders/online.orders.service';
import { OrdersServiceBuilder } from 'src/app/shared/services/orders/orders.builder';
import {
  AnyAccessType,
  isManagableItem,
} from 'src/app/shared/models/consumers';
import { Menu } from 'src/app/shared/models/menus';
import {
  GroupedOrders,
  ManagableOrder,
  Order,
  OrderTicket,
  REPEAT_OPTIONS,
  RepeatOrder,
  RepeatOrderChangedData,
  RepeatOrderData,
  ServiceStatus,
  SetOrders,
  orderToRepeatOrderData,
  ordersToOrderTickets,
  repeatOrderOptionsToPayload,
} from 'src/app/shared/models/orders';

import { FetchedManagableItem } from '../../shared/models/misc';
import {
  ApplyDefaultOrderData,
  GeneralRepeatOrdersData,
} from '../../shared/models/orders';
import {
  getFormattedDate,
  getParamsFromManagableItem,
  isObjectEmpty,
  removeUndefinedAndNull,
} from '../../shared/utils.functions';
import {
  handleHttpError,
  setGlobalLanguage,
  showSnackbarMessage,
} from '../global/global.actions';
import { selectGlobalLanguage } from '../global/global.selectors';
import { patchCurrentMenu } from '../menus/menus.actions';
import {
  selectCurrentMenu,
  selectNextMenu,
  selectPickedDate,
  selectPrevMenu,
} from '../menus/menus.selectors';
import { setOfflineOrders } from '../offline-mode/offline-mode.actions';
import {
  selectOfflineModeConsumer,
  selectOfflineModeValue,
} from '../offline-mode/offline-mode.selectors';
import {
  selectCurrentRoute,
  selectQueryParams,
  selectRouteParams,
} from '../router/router.selectors';
import {
  selectConsumer,
  selectRealTimeOrders,
  selectSimpleConsumer,
  selectUser,
} from '../user/user.selectors';
import { UtilsService } from '../../shared/services/utils.service';
import { redirectToOrders, setCurrentMenu } from './../menus/menus.actions';
import * as OrderActions from './orders.actions';
import {
  selectCurrentMenuDiets,
  selectDate,
  selectOrdersRouterExtras,
} from './orders.selectors';
import { WebsocketService } from 'src/app/shared/services/websocket/websocket.service';
import { WebsocketAction } from 'src/app/shared/models/websocket';

@Injectable()
export class OrdersEffects {
  private actions$ = inject(Actions);
  private ordersServiceBuilder = inject(OrdersServiceBuilder);
  private router = inject(Router);
  private store = inject(Store);
  private transloco = inject(TranslocoService);
  private utils = inject(UtilsService);
  private offlineOrdersService = inject(OfflineOrdersService);
  private onlineOrdersService = inject(OnlineOrdersService);

  onNavigatedToOrdersPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      filter((action: RouterAction<RouterStateSnapshot>) => {
        const url: string = action.payload?.event?.url;
        return url?.startsWith(`/orders`) && !url?.includes(`/complete`);
      }),
      withLatestFrom(
        this.store.select(selectRouteParams),
        this.store.select(selectOrdersRouterExtras),
        this.store.select(selectQueryParams),
        this.store.select(selectPickedDate),
      ),
      this.waitForMenu(),
      switchMap(([[, params, item, queryParams, pickedDate], menu]) => {
        const menuId = menu?.identifier || (params?.menuId as string);
        const date = menu?.date || (params?.date as string) || pickedDate;
        const itemParams =
          getParamsFromManagableItem(item) ??
          (Object.keys(queryParams).length > 0 ? queryParams : { own: true });
        const requestParams = {
          menuId,
          date,
          menu_name: menu.type || !menu.date ? menu.name : '',
          ...itemParams,
        };
        if (menu?.has_orders || menu.date === null) {
          return [
            OrderActions.fetchOrders({
              ...requestParams,
              fetchRepeatOrders: menu.date === null,
            }),
          ];
        } else {
          return [OrderActions.fetchRepeatOrders(requestParams)];
        }
      }),
      catchError(() => EMPTY),
    ),
  );

  fetchSource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.fetchSource),
      withLatestFrom(
        this.store.select(selectGlobalLanguage),
        this.store.select(selectCurrentMenuDiets),
        this.store.select(selectRouteParams),
        this.store.select(selectOfflineModeValue),
      ),
      switchMap(
        ([
          { menu, dietRefetch, scheduledDiets, nonRegularTranslation },
          lang,
          orderDiets,
          routeParams,
          isOffline,
        ]) => {
          // FIXME: this breaks when the user changes the language after refetching a menu with no diets for a consumer with diets
          const dietParams = {};
          if (scheduledDiets) {
            dietParams[`diet_id`] = scheduledDiets;
          } else if (dietRefetch || orderDiets) {
            dietParams[`diet_id`] = orderDiets;
          } else if (
            menu?.apply_diets &&
            ((!menu?.has_orders && menu?.show_date) ||
              (!menu?.show_date &&
                !menu?.list_orders?.includes(routeParams.date)))
          ) {
            dietParams[`diet_id`] = menu?.apply_diets;
          }
          const params = {
            ...dietParams,
            response: 'html',
            embed: true,
            ordering: true,
          };
          if (nonRegularTranslation) {
            params[`base_lang`] = nonRegularTranslation;
          } else if (menu?.translations?.includes(lang)) {
            params[`base_lang`] = lang;
          }
          if (menu?.show_date) params[`date`] = menu?.date;
          return this.ordersServiceBuilder
            .getService()
            .fetchMenuSource(
              isOffline ? menu?.source : menu?.source.split('?')[0],
              params,
            )
            .pipe(
              mergeMap((result) => {
                // remove any <script> tag from the source
                result = result.replace(
                  /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
                  '',
                );
                return [OrderActions.setSource({ source: result })];
              }),
              retry(2),
              catchError((error: unknown) => [handleHttpError({ error })]),
            );
        },
      ),
    ),
  );

  fetchProductSheetSource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.fetchProductSheet),
      withLatestFrom(
        this.store.select(selectGlobalLanguage),
        this.store.select(selectCurrentMenuDiets),
        this.store.select(selectRouteParams),
        this.store.select(selectOfflineModeValue),
      ),
      switchMap(([{ token }, lang]) => {
        this.store.dispatch(
          OrderActions.setProductSheetLoading({ productSheetLoading: true }),
        );
        const params = { response: 'html', lang: lang, embed: true };
        return this.onlineOrdersService
          .fetchProductSheetSource(token, params)
          .pipe(
            mergeMap((result) => {
              return [
                OrderActions.setProductSheetSource({
                  productSheetSource: result,
                }),
                OrderActions.setProductSheetLoading({
                  productSheetLoading: false,
                }),
              ];
            }),
            catchError((error: unknown) => [
              OrderActions.setProductSheetLoading({
                productSheetLoading: false,
              }),
              handleHttpError({ error }),
            ]),
          );
      }),
    ),
  );

  refetchDietChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.setMenuDiets),
      withLatestFrom(
        this.store.select(selectCurrentRoute),
        this.store.select(selectCurrentMenu),
      ),
      mergeMap(([, route, menu]) => {
        if (route && menu) {
          const url = (route as ActivatedRouteSnapshot).url.join(`/`);
          if (url.startsWith(`orders`) && !url.endsWith(`/complete`)) {
            return [
              OrderActions.setSource({ source: undefined }),
              OrderActions.fetchSource({ menu, dietRefetch: true }),
            ];
          }
        }
        return EMPTY;
      }),
    ),
  );

  refetchSourceLanguageChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setGlobalLanguage),
      withLatestFrom(
        this.store.select(selectCurrentRoute),
        this.store.select(selectCurrentMenu),
      ),
      mergeMap(([, route, menu]) => {
        if (route && menu) {
          const url = (route as ActivatedRouteSnapshot).url.join(`/`);
          if (url.startsWith(`orders`) && !url.endsWith(`/complete`)) {
            return [
              OrderActions.setSource({ source: undefined }),
              OrderActions.fetchSource({ menu }),
            ];
          }
        }
        return EMPTY;
      }),
    ),
  );

  fetchOrdersOfConsumer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.fetchOrdersOfConsumer),
      switchMap(({ rfid }) =>
        this.ordersServiceBuilder
          .getService()
          .fetchOrders({
            rfid,
            date: getFormattedDate(),
            mark_done: true,
          })
          .pipe(
            mergeMap((orders) => {
              if (
                orders?.length &&
                orders.filter(
                  (ord) => ord.service_status === ServiceStatus.RESERVED,
                ).length
              ) {
                return [
                  OrderActions.setOrdersCountIncrement(),
                  OrderActions.setOrders({ orders }),
                ];
              }
              return [OrderActions.setOrders({ orders })];
            }),
            catchError((error: unknown) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  fetchOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.fetchOrders),
      switchMap(
        ({
          menuId: menu,
          menu_name,
          date,
          consumer,
          created_by,
          fetchRepeatOrders: fetchRepeat,
        }) => {
          const own = !(consumer?.toString() || created_by?.toString())
            ? true
            : undefined;
          return this.ordersServiceBuilder
            .getService()
            .fetchOrders({
              menu,
              date,
              ...removeUndefinedAndNull(
                {
                  consumer,
                  created_by,
                  own,
                },
                false,
              ),
            })
            .pipe(
              mergeMap((orders) => {
                if (!orders?.length && fetchRepeat) {
                  return [
                    OrderActions.fetchRepeatOrders({
                      menu_name,
                      date,
                      consumer,
                      created_by,
                      own,
                    }),
                  ];
                }
                // FIXME: it is potentially problematic to use the first order's diets to determine the menu diets; also it has a negative performance impact
                const diets = orders?.length
                  ? orders[0].diets_detail.map((d) => d.identifier)
                  : [];
                return [
                  OrderActions.setOrders({ orders }),
                  OrderActions.setMenuDiets({ selectedDiets: diets }),
                ];
              }),
              catchError((error: unknown) => [handleHttpError({ error })]),
            );
        },
      ),
    ),
  );

  fetchOrdersCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.fetchOrdersCount),
      switchMap(({ date }) =>
        this.ordersServiceBuilder
          .getService()
          .fetchOrdersCount({ date })
          .pipe(
            mergeMap(
              (
                ordersCount: { service_status: ServiceStatus; count: number }[],
              ) => {
                const result: Action[] = [];
                for (const orderCount of ordersCount) {
                  if (orderCount.service_status === ServiceStatus.RESERVED) {
                    result.push(
                      OrderActions.setOrdersCount({
                        count: orderCount.count,
                      }),
                    );
                  } else if (orderCount.service_status === ServiceStatus.DONE) {
                    result.push(
                      OrderActions.setOrdersDoneCount({
                        count: orderCount.count,
                      }),
                    );
                  }
                }
                return result;
              },
            ),
            catchError((error: unknown) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  onCurrentMenuSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCurrentMenu),
      withLatestFrom(this.store.select(selectCurrentRoute)),
      switchMap(([{ menu, date }, currentRoute]) => {
        const url = (currentRoute as ActivatedRouteSnapshot).url.join(`/`);
        if (url.startsWith(`orders`) && !url.endsWith(`/complete`)) {
          if (!menu?.can_order && menu?.has_orders)
            return [redirectToOrders({ complete: true })];
          if (!menu?.can_order && !menu?.has_orders) {
            this.router.navigate([`menus`]);
            return EMPTY;
          }
        }
        if (!menu) {
          return [];
        }

        const selectedDiets =
          date && menu?.apply_scheduled_diets[date]
            ? menu?.apply_scheduled_diets[date]
            : menu?.apply_diets;
        return [
          OrderActions.setMenuDiets({ selectedDiets }),
          OrderActions.fetchSource({
            menu,
            scheduledDiets:
              date && menu?.apply_scheduled_diets[date]
                ? menu?.apply_scheduled_diets[date]
                : undefined,
          }),
        ];
      }),
      catchError((error: unknown) => [handleHttpError({ error })]),
    ),
  );

  sendOrdersAndPrev$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.sendOrdersAndPrev),
      withLatestFrom(
        this.store.select(selectCurrentMenu),
        this.store.select(selectPrevMenu),
        this.store.select(selectCurrentMenuDiets),
      ),
      switchMap(
        ([
          { items, editedItem, previousOrders },
          currentMenu,
          prevMenu,
          diets,
        ]) => {
          const navigationTarget = [
            `orders`,
            prevMenu.identifier,
            prevMenu.date,
          ];
          return [
            OrderActions.sendOrders({
              items,
              diets,
              editedItem,
              navigationTarget,
              ignoreSetOrders: true,
              previousOrders,
            }),
            patchCurrentMenu({
              id: currentMenu.id,
              menu: { has_orders: !!items?.length },
            }),
            setCurrentMenu({ menu: prevMenu }),
            OrderActions.setManagableItem({ item: editedItem }),
          ];
        },
      ),
    ),
  );

  sendOrdersAndNext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.sendOrdersAndNext),
      withLatestFrom(
        this.store.select(selectCurrentMenu),
        this.store.select(selectNextMenu),
        this.store.select(selectCurrentMenuDiets),
      ),
      switchMap(
        ([
          { items, editedItem, previousOrders },
          currentMenu,
          nextMenu,
          diets,
        ]) => {
          const navigationTarget = [
            `orders`,
            nextMenu.identifier,
            nextMenu.date,
          ];
          return [
            OrderActions.sendOrders({
              items,
              diets,
              editedItem,
              navigationTarget,
              ignoreSetOrders: true,
              previousOrders,
            }),
            patchCurrentMenu({
              id: currentMenu.id,
              menu: { has_orders: !!items?.length },
            }),
            setCurrentMenu({ menu: nextMenu }),
            OrderActions.setManagableItem({ item: editedItem }),
          ];
        },
      ),
      catchError((error: unknown) => [handleHttpError({ error })]),
    ),
  );

  sendOrdersAndRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.sendOrdersAndRedirect),
      withLatestFrom(
        this.store.select(selectCurrentMenu),
        this.store.select(selectDate),
        this.store.select(selectCurrentMenuDiets),
      ),
      switchMap(
        ([{ items, editedItem, previousOrders }, menu, date, diets]) => {
          const navigationTarget = [
            `orders`,
            menu.identifier,
            date,
            `complete`,
          ];
          return [
            OrderActions.sendOrders({
              items,
              diets,
              editedItem,
              navigationTarget,
              previousOrders,
            }),
            patchCurrentMenu({
              id: menu.id,
              menu: { has_orders: !!items.length },
            }),
          ];
        },
      ),
      catchError((error: unknown) => [handleHttpError({ error })]),
    ),
  );

  cancelOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.cancelOrders),
      withLatestFrom(
        this.store.select(selectCurrentMenu),
        this.store.select(selectRealTimeOrders),
      ),
      switchMap(([{ editedItem, orders }, menu]) => [
        OrderActions.sendOrders({
          items: [],
          editedItem,
          previousOrders: orders,
        }),
        patchCurrentMenu({
          id: menu.id,
          menu: { has_orders: false },
        }),
      ]),
    ),
  );

  resetDeleteRealTimeOrders$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrderActions.resetDeleteRealTimeOrders),
        mergeMap(
          ({
            orders,
            date,
            unauthConsumer,
            terminalConsumer,
            item,
            consumer,
            createdBy,
            currentUser,
          }) => {
            const target =
              unauthConsumer || terminalConsumer || item || currentUser;
            const groupedOrders: GroupedOrders = orders.reduce((acc, order) => {
              const service = order.menu_detail.show_date
                ? order.section_level2_baselang
                : order.section_level1_baselang;
              const section = order.menu_detail.show_date
                ? order.section_level3_baselang
                : order.section_level2_baselang;
              const key = `${service}-${order.service_status}-${section}`;
              if (!acc[key]) {
                acc[key] = {
                  ids: [],
                  count: 0,
                  service_status: order.service_status,
                  section,
                  service,
                };
              }
              acc[key].ids.push(order.id);
              acc[key].count += 1;
              return acc;
            }, {} as GroupedOrders);

            const extraData = {
              consumers:
                !!unauthConsumer || !!terminalConsumer || consumer
                  ? [target.id]
                  : [],
              users:
                !unauthConsumer && !terminalConsumer && !consumer
                  ? [createdBy ?? currentUser.id]
                  : [],
            };

            Object.values(groupedOrders).forEach(
              ({ ids, service, service_status, section }) => {
                this.websockets.sendMessage({
                  date,
                  service,
                  data: [
                    {
                      ids: ids,
                      service_status,
                      section,
                      items: [
                        {
                          ids,
                          service_status,
                          section,
                        },
                      ],
                      ...extraData,
                    },
                  ],
                  action: WebsocketAction.RESET_DELETE,
                });
              },
            );
            return [];
          },
        ),
      ),
    { dispatch: false },
  );

  sendOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.sendOrders),
      withLatestFrom(
        this.store.select(selectDate),
        this.store.select(selectCurrentMenu),
        this.store.select(selectQueryParams),
        this.store.select(selectOfflineModeValue),
        this.store.select(selectRealTimeOrders),
        this.store.select(selectSimpleConsumer),
        this.store.select(selectConsumer),
        this.store.select(selectUser),
      ),
      mergeMap(
        ([
          {
            items,
            diets,
            editedItem,
            navigationTarget,
            ignoreSetOrders,
            previousOrders,
          },
          currentDate,
          menu,
          queryParams,
          isOffline,
          realTime,
          unauthConsumer,
          terminalConsumer,
          currentUser,
        ]) => {
          let createdBy: number | undefined, consumer: number | undefined;
          const item = editedItem;
          if (item && isManagableItem(item)) {
            const params = getParamsFromManagableItem(item);
            if (params) {
              ({ created_by: createdBy, consumer } = params);
            }
          } else if (queryParams && !isObjectEmpty(queryParams)) {
            ({ created_by: createdBy, consumer } = queryParams);
          }
          const date = currentDate;
          const data: SetOrders & Partial<ManagableOrder> = {
            date,
            diets,
            items,
          };

          if (!data?.date) {
            console.error(`Date is null, using today's date`);
            data.date = getFormattedDate();
          }

          if (createdBy || consumer) {
            data.created_by = createdBy;
            data.consumer = consumer;
          } else {
            data.own = true;
          }

          if (isOffline) {
            this.store.dispatch(
              OrderActions.setSaveOrderButtonState({
                save_order_spinner: true,
              }),
            );
            const createdOrders = this.offlineOrdersService.getOrders();
            of(createdOrders)
              .pipe(
                withLatestFrom(this.store.select(selectOfflineModeConsumer)),
                map(([orders, consumer]) => {
                  delete data.own;
                  data['consumer'] = terminalConsumer.id;
                  // Because we are using @ngrx/entity we need to add an id to each order item
                  // to get the right results at the complete order table
                  const orderItems = data.items.map((orderedItems) => ({
                    ...orderedItems,
                    id: Math.floor(Math.random() * 100000),
                    menu: menu.identifier,
                    date,
                    consumer: terminalConsumer.id,
                  }));
                  this.offlineOrdersService.addOrder({
                    url: menu.set_orders,
                    menu: menu.identifier,
                    date: date,
                    data: {
                      ...data,
                      items: orderItems as Order[],
                    },
                    consumer: terminalConsumer.uuid,
                    status: 'waiting',
                    offline: true,
                  });
                  return orderItems;
                }),
                catchError((error: unknown) => [handleHttpError({ error })]),
              )
              .subscribe((order) => {
                const orders = this.offlineOrdersService.getOrders();
                this.store.dispatch(
                  OrderActions.setOrders({ orders: order as Order[] }),
                );
                this.store.dispatch(setOfflineOrders({ payload: orders }));
                this.store.dispatch(
                  OrderActions.setSaveOrderButtonState({
                    save_order_spinner: false,
                  }),
                );
                if (navigationTarget)
                  this.router.navigate(navigationTarget, { queryParams });
              });
            return of(); // just return nothing
          }
          this.store.dispatch(
            OrderActions.setSaveOrderButtonState({ save_order_spinner: true }),
          );
          return this.utils
            .post<
              SetOrders & Partial<ManagableOrder>,
              Order[]
            >(menu.set_orders, data)
            .pipe(
              mergeMap((orders) => {
                this.store.dispatch(
                  OrderActions.setSaveOrderButtonState({
                    save_order_spinner: false,
                  }),
                );
                if (realTime && orders.length > 0) {
                  // If we have previousOrders, we need to delete the old orders first
                  if (previousOrders) {
                    const target =
                      unauthConsumer || terminalConsumer || item || currentUser;
                    const extraData = {
                      consumers:
                        !!unauthConsumer || !!terminalConsumer || consumer
                          ? [target.id]
                          : [],
                      users:
                        !unauthConsumer && !terminalConsumer && !consumer
                          ? [createdBy ?? currentUser.id]
                          : [],
                    };
                    const previousOrderTickets = ordersToOrderTickets(
                      previousOrders,
                      date,
                      unauthConsumer,
                      terminalConsumer,
                      item,
                      currentUser,
                    );
                    const groupedPreviousOrderTickets = Object.values(
                      previousOrderTickets,
                    ).reduce(
                      (acc, orderTicket) => {
                        const key = `${orderTicket.service}-${orderTicket.service_status}-${orderTicket.section}`;
                        if (!acc[key]) {
                          acc[key] = {
                            service: orderTicket.service,
                            service_status: orderTicket.service_status,
                            section: orderTicket.section,
                            tickets: [],
                            ids: [],
                            count: 0,
                          };
                        }
                        acc[key]['tickets'].push(orderTicket);
                        acc[key]['count'] += 1;
                        acc[key]['ids'].push(...orderTicket.ids);
                        return acc;
                      },
                      {} as Record<
                        string,
                        {
                          service: string;
                          service_status: ServiceStatus;
                          section: string;
                          tickets: OrderTicket[];
                          count: number;
                          ids: number[];
                        }
                      >,
                    );

                    Object.values(groupedPreviousOrderTickets).forEach(
                      ({ service, service_status, section, count, ids }) => {
                        // send an empty reset message to clear the reservation
                        this.websockets.sendMessage({
                          date,
                          service,
                          data: {
                            ids: [],
                            items: [],
                            ...extraData,
                          },
                          action: WebsocketAction.RESET,
                        });
                        // send a delete message to delete the old orders, but keep the reservation for the new orders
                        this.websockets.sendMessage({
                          date,
                          service,
                          data: {
                            ids,
                            count,
                            section: section ?? '',
                            service_status,
                          },
                          action: WebsocketAction.DELETE,
                        });
                      },
                    );
                  }
                  // Create the order tickets
                  const orderTickets = ordersToOrderTickets(
                    orders,
                    date,
                    unauthConsumer,
                    terminalConsumer,
                    item,
                    currentUser,
                  );
                  const groupedOrderTickets = Object.values(
                    orderTickets,
                  ).reduce(
                    (acc, orderTicket) => {
                      if (!acc[orderTicket.service]) {
                        acc[orderTicket.service] = [];
                      }
                      acc[orderTicket.service].push(orderTicket);
                      return acc;
                    },
                    {} as Record<string, OrderTicket[]>,
                  );
                  Object.entries(groupedOrderTickets).forEach(
                    ([service, tickets]) => {
                      this.websockets.sendMessage({
                        date,
                        service,
                        data: tickets,
                        action: WebsocketAction.CREATE,
                      });
                    },
                  );
                } else if (realTime && orders.length === 0) {
                  this.store.dispatch(
                    OrderActions.resetDeleteRealTimeOrders({
                      orders: previousOrders,
                      date,
                      unauthConsumer,
                      terminalConsumer,
                      item,
                      consumer,
                      createdBy,
                      currentUser,
                    }),
                  );
                }

                if (navigationTarget)
                  this.router.navigate(navigationTarget, { queryParams });
                return ignoreSetOrders
                  ? EMPTY
                  : [OrderActions.setOrders({ orders })];
              }),
              catchError((error: unknown) => {
                this.store.dispatch(
                  OrderActions.setSaveOrderButtonState({
                    save_order_spinner: false,
                  }),
                );
                return [handleHttpError({ error, forceSnackbar: true })];
              }),
            );
        },
      ),
    ),
  );

  fetchConsumer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.fetchManagableItem),
      switchMap(({ created_by, consumer }) => {
        const getObservable: Observable<FetchedManagableItem> = created_by
          ? this.ordersServiceBuilder.getService().getUser(created_by)
          : this.ordersServiceBuilder.getService().getConsumer(consumer);
        return getObservable.pipe(
          mergeMap((user) => [OrderActions.setManagableItem({ item: user })]),
          catchError((error: unknown) => [
            handleHttpError({ error, forceSnackbar: true }),
          ]),
        );
      }),
    ),
  );

  repeatOrderChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.repeatOrderChanged),
      debounceTime(300),
      map(({ repeatOrderOption, order, repeatOrder, url, menu }) => {
        if (repeatOrderOption.option === REPEAT_OPTIONS.DO_NO_REPEAT) {
          return OrderActions.deleteRepeatOrder({
            id: repeatOrderOption.payload.id,
            url,
          });
        }
        const data: RepeatOrderChangedData =
          repeatOrderOptionsToPayload(repeatOrderOption);
        data['menu'] = menu;
        this.store.dispatch(
          showSnackbarMessage({
            message: this.transloco.translate(this.REPETITION_SAVED),
            button: this.transloco.translate(this.GOT_IT),
          }),
        );
        if (url) {
          return OrderActions.changeRepeatOrder({
            changeRepeatOrderData: data,
            url,
          });
        }
        if (repeatOrder) {
          return OrderActions.createRepeatOrder({
            createRepeatOrderData: data,
            repeatOrder,
          });
        }
        return OrderActions.createRepeatOrderFromOrder({
          createRepeatOrderData: data,
          order,
        });
      }),
    ),
  );

  createRepeatOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.createRepeatOrder),
      switchMap(({ createRepeatOrderData, repeatOrder }) => {
        const data: RepeatOrderData = Object.assign(
          repeatOrder,
          createRepeatOrderData,
        );
        return this.ordersServiceBuilder
          .getService()
          .createRepeatOrder(data)
          .pipe(
            mergeMap((repeatOrder) => [
              OrderActions.setCreatedRepeatOrder({
                repeatOrder,
              }),
            ]),
            catchError((error: unknown) => [
              handleHttpError({ error, forceSnackbar: true }),
            ]),
          );
      }),
    ),
  );

  createRepeatOrderFromOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.createRepeatOrderFromOrder),
      switchMap(({ createRepeatOrderData, order }) => {
        const data: RepeatOrderData = orderToRepeatOrderData(order);
        Object.assign(data, createRepeatOrderData);
        return this.ordersServiceBuilder
          .getService()
          .createRepeatOrder(data)
          .pipe(
            mergeMap((repeatOrder) => [
              OrderActions.setCreatedRepeatOrder({
                repeatOrder,
              }),
            ]),
            catchError((error: unknown) => [
              handleHttpError({ error, forceSnackbar: true }),
            ]),
          );
      }),
    ),
  );

  changeRepeatOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.changeRepeatOrder),
      switchMap(({ changeRepeatOrderData, url }) =>
        this.utils
          .patch<Partial<RepeatOrder>, RepeatOrder>(url, changeRepeatOrderData)
          .pipe(
            mergeMap((changedRepeatOrder) => [
              OrderActions.setUpdatedRepeatOrder({
                repeatOrder: {
                  id: changedRepeatOrder.id,
                  changes: changedRepeatOrder,
                },
              }),
            ]),
            catchError((error: unknown) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  deleteRepeatOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.deleteRepeatOrder),
      switchMap(({ url, id }) =>
        this.utils.delete(url).pipe(
          switchMap(() => [
            showSnackbarMessage({
              message: this.transloco.translate(this.REPETITION_SAVED),
              button: this.transloco.translate(this.GOT_IT),
            }),
            OrderActions.setDeletedRepeatOrder({
              id,
            }),
          ]),
          catchError((error: unknown) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  fetchRepeatOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.fetchRepeatOrders),
      switchMap(({ menu_name, date, consumer, created_by, own, manage }) =>
        this.ordersServiceBuilder
          .getService()
          .fetchRepeatOrders({
            menu_name,
            date,
            ...removeUndefinedAndNull(
              {
                consumer,
                created_by,
                own,
              },
              false,
            ),
          })
          .pipe(
            map((repeatOrders) =>
              manage
                ? OrderActions.setRepeatOrdersManage({ repeatOrders })
                : OrderActions.repeatOrdersFetched({ repeatOrders }),
            ),
            catchError((error: unknown) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  setGeneralRepeatOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.setGeneralRepeatOrders),
      withLatestFrom(
        this.store.select(selectDate),
        this.store.select(selectQueryParams),
      ),
      this.waitForMenu(),
      switchMap(
        ([
          [
            { repeatOrderOptionData: optionData },
            date,
            { consumer, created_by },
          ],
          menu,
        ]) => {
          const originalData: Partial<GeneralRepeatOrdersData> = {};
          if (consumer || created_by) {
            originalData.created_by = created_by ? +created_by : null;
            originalData.consumer = consumer ? +consumer : null;
          } else {
            originalData.own = true;
          }
          const data = {
            date,
            ...removeUndefinedAndNull(originalData),
          };
          switch (optionData.option) {
            case REPEAT_OPTIONS.REPEAT_DAILY:
              data.repeat_dates = [];
              data.repeat_weekdays = [];
              data.repeat_daily_from = null;
              break;
            case REPEAT_OPTIONS.WEEKDAYS:
              data.repeat_dates = [];
              data.repeat_weekdays = optionData.payload;
              data.repeat_daily_from = null;
              break;
            case REPEAT_OPTIONS.DATES:
              data.repeat_dates = optionData.payload;
              data.repeat_weekdays = [];
              data.repeat_daily_from = null;
              break;
            case REPEAT_OPTIONS.REPEAT_DAILY_FROM:
              data.repeat_dates = [];
              data.repeat_weekdays = [];
              data.repeat_daily_from = optionData.payload;
              break;
          }
          let requestObservable: Observable<RepeatOrder[] | undefined>;
          if (optionData.option === REPEAT_OPTIONS.DO_NO_REPEAT) {
            requestObservable = this.utils.delete<undefined>(
              menu.set_repeated_orders,
              undefined,
              data,
            );
          } else {
            requestObservable = this.ordersServiceBuilder
              .getService()
              .setGeneralRepeatOrders(menu.set_repeated_orders, data);
          }
          this.store.dispatch(
            showSnackbarMessage({
              message: this.transloco.translate(this.REPETITION_SAVED),
              button: this.transloco.translate(this.GOT_IT),
            }),
          );
          return requestObservable.pipe(
            map((repeatOrders) =>
              OrderActions.setRepeatOrdersManage({
                repeatOrders: repeatOrders ?? [],
              }),
            ),
            catchError((error: unknown) => [handleHttpError({ error })]),
          );
        },
      ),
    ),
  );

  applyDefaultOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.applyDefaultOrder),
      withLatestFrom(
        this.store.select(selectGlobalLanguage),
        this.store.select(selectCurrentMenu),
        this.store.select(selectDate),
        this.store.select(selectQueryParams),
      ),
      switchMap(
        ([
          { orderData, selectedDiets },
          language,
          menu,
          date,
          { created_by, consumer },
        ]) => {
          const data = removeUndefinedAndNull(
            {
              date: menu.show_date ? date : null,
              language,
              diets: selectedDiets,
              created_by: created_by ? +created_by : null,
              consumer: consumer ? +consumer : null,
            },
            false,
          );
          const params = {};
          if (consumer) {
            params['consumer'] = +consumer;
          } else if (created_by) {
            params['created_by'] = +consumer;
          }
          return this.utils
            .post<
              Partial<ApplyDefaultOrderData>,
              RepeatOrder[]
            >(menu.get_defaults, data, params)
            .pipe(
              map((repeatOrders) => {
                const fields = [
                  'consistency' as keyof AnyAccessType,
                  'texture' as keyof AnyAccessType,
                  'portion_size' as keyof AnyAccessType,
                ].filter((f) => !!orderData[f]);
                if (fields) {
                  repeatOrders = repeatOrders.map((order) => {
                    fields.forEach(
                      (field) => (order[field] = orderData[field]),
                    );
                    return order;
                  });
                }
                return OrderActions.repeatOrdersFetched({ repeatOrders });
              }),
              catchError((error: unknown) => [handleHttpError({ error })]),
            );
        },
      ),
    ),
  );

  readonly REPETITION_SAVED = 'orders.repetition-saved';
  readonly GOT_IT = 'orders.got-it';

  waitForMenu<T>() {
    return switchMap<T, Observable<[T, Menu]>>((prev: T) =>
      this.store.select(selectCurrentMenu).pipe(
        filter((menu) => !!menu),
        take(1),
        map((menu) => [prev, menu]),
      ),
    );
  }

  constructor(private websockets: WebsocketService) {}
}
