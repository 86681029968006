@if ((emailVerified$ | async) === true || (isSimpleAuth$ | async) === true) {
<win-menus
  class="menus-wrapper"
  [consumer]="terminalConsumer$ | async"
  [dailyMenus]="dailyMenus$ | async"
  [fullName]="userFullName$ | async"
  [goBackPage]="backQueryParam$ | async"
  [isAdmin]="isAdmin$ | async"
  [isConsumer]="(isConsumer$ | async) || (simpleConsumer$ | async)"
  [isKdsUser]="(isKdsUser$ | async)"
  [isServiceUser]="(isServiceUser$ | async)"
  [isAgent]="isAgent$ | async"
  [isManager]="isManager$ | async"
  [isOffline]="isOffline$ | async"
  [isTerminal]="isTerminal$ | async"
  [isUnauth]="(isSimpleAuth$ | async)"
  [item]="managableItem$ | async"
  [lang]="globalLanguage$ | async"
  [menus]="menus$ | async"
  [offlineDate]="offlineDate$ | async"
  [staffOrdertaking]="staffOrdertaking$ | async"
  [showConsumerInfo]="selectShowConsumerInfo$ | async"
  [showConsumerInfoDetail]="selectShowConsumerInfoDetail$ | async"
  [specialMenus]="specialMenus$ | async"
  [showThirdMenuCard]="showThirdCard$ | async"
  (logout)="logout()"
  (selectMenu)="selectMenu($event)"
  (selectMenuShowDatepicker)="selectMenuShowDatepicker($event)"
  (goBack)="goBack()"
  (changeCheckoutDate)="changeCheckoutDate($event)"
></win-menus>

<input
  class="date-input"
  type="hidden"
  [matDatepicker]="picker"
  [matDatepickerFilter]="dateFilter"
  #dateInput
/>
<mat-datepicker
  touchUi
  #picker
  [startAt]="minDate"
  [dateClass]="dateClass"
></mat-datepicker>

@if ((isTerminal$ | async) === false && (managableItem$ | async) === null &&
(isSimpleAuth$ | async) === false) { @if ((availableReports$ | async)?.length) {
<win-consumer-reports
  class="reports-wrapper"
  [availableReports]="availableReports$ | async"
  [downloadingReports]="downloadingReports$ | async"
  (downloadReport)="downloadReport($event)"
  (openReport)="openReport($event)"
></win-consumer-reports>
} @if ((isManager$ | async) || (isAgent$ | async)) {
<win-build-new-skills
  class="skills-wrapper"
  [courses]="courses$ | async"
  [isManager]="isManager$ | async"
  [lang]="globalLanguage$ | async"
  [loading]="loadingCourses$ | async"
  (addNewCourse)="addNewCourse()"
  (deleteCourse)="deleteCourse($event)"
  (editCourse)="editCourse($event)"
></win-build-new-skills>
} } } @else {
<win-verify-email></win-verify-email>
}
