import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoPipe } from '@jsverse/transloco';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { resendEmailConfirmation } from './../../../redux/authentication/authentication.actions';
import {
  selectUserEmail,
  selectUserFullName,
} from './../../../redux/user/user.selectors';

@Component({
  selector: 'win-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
  standalone: true,
  imports: [AsyncPipe, MatButtonModule, RouterLink, TranslocoPipe],
})
export class VerifyEmailComponent {
  private store = inject(Store);

  emailResent = false;

  userFullName$ = this.store.pipe(select(selectUserFullName));
  userEmail$ = this.store
    .pipe(select(selectUserEmail))
    .pipe(map((email) => ({ email })));

  resendEmail(): void {
    this.emailResent = true;
    this.store.dispatch(resendEmailConfirmation());
  }
}
