<h2 mat-dialog-title class="mat-h2">
  {{(data?.key || 'login.verify-password-dialog') | transloco}}
</h2>

<div mat-dialog-content class="wrapper">
  <win-form-with-errors [formGroup]="formGroup" [formId]="formId">
    <div class="fields">
      <win-field-with-errors
        icon="lock"
        type="password"
        [label]="'registration.password1.placeholder' | transloco"
        translationKey="registration.password1.errors"
        formControlName="password"
        [required]="true"
        (keyup.enter)="verifyPassword()"
      >
      </win-field-with-errors>
    </div>
  </win-form-with-errors>
</div>

<div mat-dialog-actions class="actions">
  <button mat-button class="win-button-light" mat-dialog-close>
    {{'shared.cancel' | transloco}}
  </button>
  <button mat-button class="win-button" (click)="verifyPassword()">
    {{'shared.save' | transloco}}
  </button>
</div>
