import { Injectable, inject } from '@angular/core';
import { API_CONFIG } from 'src/app/app.config';
import {
  ConfirmPasswordResetForm,
  ConsumerNameTerminalLoginForm,
  GenericName,
  LoginForm,
  LoginSuccess,
  RegistrationForm,
  ResetSuccess,
  RoomLoginForm,
  VerifySuccess,
} from 'src/app/shared/models/authentication';
import { Consumer } from 'src/app/shared/models/consumers';
import { InvitationResult, User } from 'src/app/shared/models/user';

import { Organisation, RoomConsumer } from '../../models/user';
import { AuthenticationService } from './authentication.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

export const TOKEN_KEY = 'mt_orders_access_token';
export const REFRESH_TOKEN_KEY = 'mt_orders_refresh_token';

@Injectable({ providedIn: 'root' })
export class OnlineAuthenticationService implements AuthenticationService {
  private utilsService = inject(UtilsService);

  private loginEP = `${API_CONFIG.orderTakingApi}/rest-auth/login/`;
  private emailVerificationEP = `${API_CONFIG.orderTakingApi}/users/verify_email/`;
  private registrationEP = `${API_CONFIG.orderTakingApi}/rest-auth/registration/`;
  private resetPasswordEP = `${API_CONFIG.orderTakingApi}/rest-auth/password/reset/`;
  private confirmEmailEP = `${API_CONFIG.orderTakingApi}/rest-auth/registration/verify-email/`;
  private confirmPasswordResetEP = `${API_CONFIG.orderTakingApi}/rest-auth/password/reset/confirm/`;
  private resendEmailConfirmationEP = `${API_CONFIG.orderTakingApi}/rest-auth/registration/resend_email_confirmation/`;
  private refreshTokenEP = `${API_CONFIG.orderTakingApi}/rest-auth/token/refresh/`;
  private checkInvitationEP = `${API_CONFIG.orderTakingApi}/invitations/check_invitation/`;

  private verifyPasswordEP = (userUrl: string) => `${userUrl}verify_password/`;
  private roomLoginEP = (uid: number) =>
    `${API_CONFIG.orderTakingApi}/users/${uid}/login_terminal_session/`;
  private retrieveUuidEP = (key: string, auth_room: string) =>
    `${API_CONFIG.orderTakingApi}/consumers/retrieve_uuid/?key=${key}&auth_room=${auth_room}`;
  private roomSessionLoginEP = (uid: number) =>
    `${API_CONFIG.orderTakingApi}/users/${uid}/login_room_session/`;
  private validateRoomEP = (uid: number) =>
    `${API_CONFIG.orderTakingApi}/users/${uid}/validate_room/`;
  private logoutFromRoomEP = (uid: number) =>
    `${API_CONFIG.orderTakingApi}/users/${uid}/logout_terminal_session/`;
  private validateKeyEP = (key: string) =>
    `${API_CONFIG.orderTakingApi}/consumers/validate_organisation/?key=${key}`;

  consumerTerminalLogin = (uid: number, data: { uuid: string }) =>
    this.utilsService.post<{ uuid: string }, Consumer | User>(
      this.roomLoginEP(uid),
      data,
    );

  retrieveUuid = (consumerName: GenericName, key: string, auth_room: string) =>
    this.utilsService.post<GenericName, { uuid: string }>(
      this.retrieveUuidEP(key, auth_room),
      consumerName,
    );

  consumerNameTerminalLogin = (
    uid: number,
    data: ConsumerNameTerminalLoginForm,
  ) =>
    this.utilsService.post<ConsumerNameTerminalLoginForm, Consumer>(
      this.roomSessionLoginEP(uid),
      data,
    );

  validateRoom = (uid: number, data: { room?: string }) =>
    this.utilsService.post<
      { room?: string },
      { message: string } | RoomConsumer[]
    >(this.validateRoomEP(uid), data);

  validateKey = (key: string) =>
    this.utilsService.post<Record<string, never>, Organisation>(
      this.validateKeyEP(key),
      {},
    );

  login = (data: LoginForm) =>
    this.utilsService.post<LoginForm, LoginSuccess>(this.loginEP, data);

  refreshToken = (refreshToken: string) =>
    this.utilsService.post<
      { refresh: string },
      { access: string; refresh: string }
    >(this.refreshTokenEP, {
      refresh: refreshToken,
    });

  logoutFromRoom = (uid: number) =>
    this.utilsService.post<Record<string, never>, { message: string }>(
      this.logoutFromRoomEP(uid),
      {},
    );

  roomLogin = (uid: number, data: RoomLoginForm) =>
    this.utilsService.post<RoomLoginForm, Consumer | User>(
      this.roomLoginEP(uid),
      data,
    );

  register = (data: RegistrationForm) =>
    this.utilsService.post<RegistrationForm, LoginSuccess>(
      this.registrationEP,
      data,
    );

  checkInvitation = (key: string) =>
    this.utilsService.post<{ key: string }, InvitationResult>(
      this.checkInvitationEP,
      { key },
    );

  resetPassword = (data: { email: string }) =>
    this.utilsService.post<{ email: string }, { detail: string }>(
      this.resetPasswordEP,
      data,
    );

  saveToken = (data: LoginSuccess | ResetSuccess) => {
    this.saveAccessToken(data?.access);
    localStorage.setItem(REFRESH_TOKEN_KEY, data.refresh);
  };

  saveAccessToken = (access: string, refresh?: string) => {
    localStorage.setItem(TOKEN_KEY, access);
    if (refresh) localStorage.setItem(REFRESH_TOKEN_KEY, refresh);
  };

  removeToken = () => {
    localStorage.clear();
  };

  getToken = (): string | undefined => localStorage.getItem(TOKEN_KEY);

  getRefreshToken = (): string | undefined =>
    localStorage.getItem(REFRESH_TOKEN_KEY);

  // checks email's validity before registration
  checkEmail = (email: string) =>
    this.utilsService.post<{ email: string }, { message: string }>(
      this.emailVerificationEP,
      { email },
    );

  confirmEmail = (key: string) =>
    this.utilsService.post<{ key: string }, VerifySuccess>(
      this.confirmEmailEP,
      { key },
    );

  confirmPasswordReset = (data: ConfirmPasswordResetForm) =>
    this.utilsService.post<ConfirmPasswordResetForm, LoginSuccess>(
      this.confirmPasswordResetEP,
      data,
    );

  resendEmailConfirmation = () =>
    this.utilsService.post<Record<string, never>, { message: string }>(
      this.resendEmailConfirmationEP,
      {},
    );

  verifyPassword = (userUrl: string, password: string) =>
    this.utilsService.post<{ password: string }, { message: string }>(
      this.verifyPasswordEP(userUrl),
      {
        password,
      },
    );
}
