import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterLink } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { SUPPORT_EMAIL_TOKEN } from 'src/app/app.config';
import { confirmEmail } from 'src/app/redux/authentication/authentication.actions';
import {
  selectConfirmEmailLoadingState,
  selectConfirmEmailResult,
} from 'src/app/redux/authentication/authentication.selectors';
import { selectRouteParam } from 'src/app/redux/router/router.selectors';

@Component({
  selector: 'win-confirm-email',
  templateUrl: './confirm-email.container.html',
  styleUrls: ['./confirm-email.container.scss'],
  standalone: true,
  imports: [MatProgressSpinnerModule, RouterLink, AsyncPipe, TranslocoPipe],
})
export class ConfirmEmailContainer implements OnInit {
  private destroyRef = inject(DestroyRef);
  private store = inject(Store);
  supportEmail = inject(SUPPORT_EMAIL_TOKEN);

  private readonly confirmationKey = `key`;
  private keySelector$ = this.store.pipe(
    select(selectRouteParam(this.confirmationKey)),
    filter((key) => !!key),
  );

  confirmationResult$ = this.store.pipe(select(selectConfirmEmailResult));
  loadingState$ = this.store.pipe(select(selectConfirmEmailLoadingState));

  ngOnInit(): void {
    this.keySelector$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((key) => {
        this.store.dispatch(confirmEmail({ key }));
      });
  }
}
