import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  MatDialogRef,
  MatDialogTitle,
  MatDialogActions,
  MatDialogContent,
} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { withLatestFrom } from 'rxjs';
import {
  selectOfflineDataDownloading,
  selectOfflineOrderCount,
  selectOfflineOrdersSubmitting,
} from 'src/app/redux/offline-mode/offline-mode.selectors';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';

@Component({
  selector: 'win-offline-mode-spinner',
  templateUrl: './offline-mode-spinner.component.html',
  styleUrls: ['./offline-mode-spinner.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatProgressSpinnerModule,
    NgTemplateOutlet,
    TranslocoPipe,
  ],
})
export class OfflineModeSpinnerComponent {
  private destroyRef = inject(DestroyRef);
  private dialogRef =
    inject<MatDialogRef<OfflineModeSpinnerComponent>>(MatDialogRef);
  private store = inject(Store);

  isDownloading$ = this.store.pipe(select(selectOfflineDataDownloading));
  isSubmitting$ = this.store.pipe(select(selectOfflineOrdersSubmitting));
  offlineOrderCount$ = this.store.pipe(select(selectOfflineOrderCount));

  constructor() {
    this.isDownloading$
      .pipe(
        withLatestFrom(this.isSubmitting$),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(([downloading, submitting]) => {
        this.autoCloseModal(submitting, downloading);
      });

    this.isSubmitting$
      .pipe(
        withLatestFrom(this.isDownloading$),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(([submitting, downloading]) => {
        this.autoCloseModal(submitting, downloading);
      });
  }

  autoCloseModal(...args: boolean[]) {
    if (args.every((arg) => !arg)) this.dialogRef.close();
  }
}
