import {
  APP_INITIALIZER,
  ApplicationConfig,
  InjectionToken,
  isDevMode,
  ErrorHandler,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
  provideRouter,
  withInMemoryScrolling,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';

import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
  withXsrfConfiguration,
} from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { TranslocoHttpLoader } from './transloco-loader';
import { provideTransloco } from '@jsverse/transloco';
import { routes } from './app.routes';
import { environment } from '../environments/environment';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { provideStore, Store } from '@ngrx/store';
import { extModules } from './build-specifics';
import { AuthenticationEffects } from './redux/authentication/authentication.effects';
import { CoursesEffects } from './redux/courses/courses.effects';
import { DietsEffects } from './redux/diets/diets.effects';
import { GlobalEffects } from './redux/global/global.effects';
import { MenusEffects } from './redux/menus/menus.effects';
import { OfflineModeEffects } from './redux/offline-mode/offline-mode.effects';
import { OrdersEffects } from './redux/orders/orders.effects';
import { reducer } from './redux/store';
import { UserEffects } from './redux/user/user.effects';
import { checkAuthenticationQueryParamsFactory } from './shared/utils.functions';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { enUS } from 'date-fns/locale';
import {
  DateFnsAdapter,
  MAT_DATE_FNS_FORMATS,
} from '@angular/material-date-fns-adapter';
import { provideServiceWorker } from '@angular/service-worker';
import { registerLocaleData } from '@angular/common';

import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIT from '@angular/common/locales/it';
import { authInterceptor } from './shared/services/interceptor/interceptor.service';

export const API_CONFIG = {
  orderTakingApi: environment.apiUrl,
  mealPlanningApi: environment.mealPlanningApiUrl,
};

export const SUPPORT_EMAIL_TOKEN = new InjectionToken<string>('support-email');

export const TERMS_LINKS = {
  en: 'https://menutech.com/en/terms-and-conditions',
  de: 'https://menutech.com/de/allgemeine-geschaeftsbedingungen',
  it: 'https://menutech.com/it/condizioni-generali',
  fr: 'https://menutech.com/fr/termes-et-conditions',
  es: 'https://menutech.com/es/terminos-y-condiciones-generales',
};

registerLocaleData(localeFr);
registerLocaleData(localeDe);
registerLocaleData(localeEs);
registerLocaleData(localeIT);

const isE2EMode = !!(window as any).Cypress;

export const appConfig: ApplicationConfig = {
  providers: [
    // Angular providers
    provideRouter(
      routes,
      ...(!isE2EMode ? [withViewTransitions()] : []),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
      }),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
    ),
    provideHttpClient(
      withInterceptors([authInterceptor]),
      withXsrfConfiguration({
        cookieName: 'csrftoken',
        headerName: 'X-CSRFToken',
      }),
    ),
    provideAnimations(),

    // NgRx providers
    provideStore(reducer),
    provideRouterStore(),
    provideEffects([
      AuthenticationEffects,
      CoursesEffects,
      DietsEffects,
      GlobalEffects,
      MenusEffects,
      OfflineModeEffects,
      OrdersEffects,
      UserEffects,
    ]),
    extModules,

    // Sentry providers
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: !environment.production,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },

    // Custom providers
    {
      provide: APP_INITIALIZER,
      useFactory: checkAuthenticationQueryParamsFactory,
      deps: [ActivatedRoute, Store, HttpClient],
      multi: true,
    },
    {
      provide: SUPPORT_EMAIL_TOKEN,
      useValue: 'support@menutech.com',
    },

    // Transloco providers
    provideTransloco({
      config: {
        availableLangs: ['en', 'de', 'it', 'es', 'fr'],
        defaultLang: 'en',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoMessageformat(),

    // Service workers
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode() && !isE2EMode,
      registrationStrategy: 'registerWhenStable:30000',
    }),

    // DateFns providers
    { provide: MAT_DATE_LOCALE, useValue: enUS },
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },

    // Angular Material providers
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
  ],
};
