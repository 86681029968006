import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivateFn } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { clearOrdersPage } from 'src/app/redux/orders/orders.actions';
import { selectOrdersSelectionState } from 'src/app/redux/orders/orders.selectors';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import { isOrders, showLeaveDialog } from 'src/app/shared/utils.functions';

export const deactivateOrdersGuard: CanDeactivateFn<any> = (
  _component,
  _currentRoute,
  _currentState,
  nextState,
) => {
  const dialog = inject(MatDialog);
  const store = inject(Store);
  const clearOrders = (url = ''): void => {
    if (!isOrders(url)) store.dispatch(clearOrdersPage());
  };

  if (!showLeaveDialog(nextState?.url)) return of(true);

  const openDialog = (): Observable<boolean> => {
    const dialogRef = dialog.open(SimpleDialogComponent, {
      width: '600px',
      maxWidth: '100vw',
      autoFocus: false,
      disableClose: true,
      data: {
        title: 'orders.unsaved-dialog.title',
        text: 'orders.unsaved-dialog.description',
        cancelText: 'orders.unsaved-dialog.keep-btn',
        confirmText: 'orders.unsaved-dialog.leave-btn',
        confirmIcon: 'delete',
        cancelable: true,
        warn: true,
      },
    });
    return dialogRef.afterClosed().pipe(
      tap((value) => {
        if (value) clearOrders();
      }),
    );
  };

  return store.pipe(
    select(selectOrdersSelectionState),
    take(1),
    switchMap((state) => {
      if (state) return openDialog();
      clearOrders();
      return of(true);
    }),
  );
};
