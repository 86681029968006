import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { PasswordValidators } from 'ngx-validators';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { TERMS_LINKS } from 'src/app/app.config';
import { register } from 'src/app/redux/authentication/authentication.actions';
import { AuthenticationServiceBuilder } from 'src/app/shared/services/authentication/authentication.builder';

import { checkInvitation } from '../../redux/authentication/authentication.actions';
import {
  selectInvitationError,
  selectInvitationResult,
  selectRegistertButtonState,
} from '../../redux/authentication/authentication.selectors';
import { selectGlobalLanguage } from '../../redux/global/global.selectors';
import { InvitationResult } from '../../shared/models/user';
import { AsyncEmailValidator } from '../../shared/validators/async-email.validator';
import { RegistrationForm } from './../../shared/models/authentication';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FieldWithErrorsComponent } from '../../shared/components/field-with-errors/field-with-errors.component';
import { FormWithErrorsComponent } from '../../shared/components/form-with-errors/form-with-errors.component';
import { InviterInfoComponent } from './inviter-info/inviter-info.component';
import { AsyncPipe, KeyValuePipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'win-registration',
  templateUrl: './registration.container.html',
  styleUrls: [
    '../../shared/styles/authentication.scss',
    '../login/simple-login/simple-login.component.scss',
    './registration.container.scss',
  ],
  standalone: true,
  imports: [
    MatCardModule,
    InviterInfoComponent,
    FormWithErrorsComponent,
    ReactiveFormsModule,
    FieldWithErrorsComponent,
    MatCheckboxModule,
    MatFormFieldModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    RouterLink,
    AsyncPipe,
    KeyValuePipe,
    TranslocoPipe,
  ],
})
export class RegistrationContainer implements OnInit {
  private authServiceBuilder = inject(AuthenticationServiceBuilder);
  private destroyRef = inject(DestroyRef);
  private fb = inject(FormBuilder);
  private store = inject(Store);
  private route = inject(ActivatedRoute);

  globalLanguage$ = this.store.pipe(select(selectGlobalLanguage));
  invitationErrorMsg$ = this.store.pipe(select(selectInvitationError));
  spinnerState$ = this.store.pipe(select(selectRegistertButtonState));
  invitationResult$ = this.store.pipe(select(selectInvitationResult)).pipe(
    filter((v) => !!v),
    tap((data) => this.invitationSuccess(data)),
  );

  registrationForm: FormGroup;
  checkingEmail$: Observable<boolean>;
  termsLinks = TERMS_LINKS;
  invitation: boolean;

  ngOnInit(): void {
    this.invitation = this.route.snapshot.data?.invitation;
    const key = this.route.snapshot.params.key;
    if (this.invitation) {
      this.store.dispatch(checkInvitation({ key }));
    }
    this.buildForm();
    this.checkingEmail$ = this.registrationForm.statusChanges.pipe(
      map(() => this.registrationForm.status === 'PENDING'),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  buildForm(): void {
    this.registrationForm = this.fb.group(
      {
        first_name: [
          '',
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(24),
          ],
        ],
        last_name: [
          '',
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(24),
          ],
        ],
        email: [
          '',
          {
            validators: [Validators.required, Validators.email],
            asyncValidators: this.invitation
              ? null
              : [
                  AsyncEmailValidator.createValidator(
                    this.authServiceBuilder,
                    this.store,
                  ),
                ],
            updateOn: 'blur',
          },
        ],
        terms: [false, [Validators.required, Validators.requiredTrue]],
        password1: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(32),
          ],
        ],
        password2: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(32),
          ],
        ],
      },
      {
        validators: [
          PasswordValidators.mismatchedPasswords(`password1`, `password2`),
        ],
      },
    );

    if (this.invitation) {
      this.registrationForm.disable();
    }
  }

  onSubmit(): void {
    if (!this.registrationForm.valid) return;

    this.registrationForm.controls?.[`terms`].markAsTouched(); // fix terms error not showing after pressing the submit button
    const formValue = this.registrationForm.value as RegistrationForm;
    if (this.invitation) {
      formValue.email = this.registrationForm.get(`email`).value;
    }
    this.globalLanguage$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val: string) => {
        formValue.language = val;
      });
    this.store.dispatch(register({ payload: formValue }));
  }

  invitationSuccess(data: InvitationResult): void {
    this.registrationForm.enable();
    this.registrationForm.patchValue(
      {
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
      },
      { emitEvent: false },
    );
    this.registrationForm.get(`email`).disable();
  }
}
