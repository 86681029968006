import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  Consistency,
  Order,
  OrderVariant,
  Texture,
} from 'src/app/shared/models/orders';

import { OrderDescriptionComponent } from './order-description/order-description.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { SaveStripeComponent } from '../../../../shared/components/save-stripe/save-stripe.component';
import { OrderVariantComponent } from './order-variant/order-variant.component';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Store, select } from '@ngrx/store';
import { selectAutocompleteSearch } from 'src/app/redux/global/global.selectors';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'win-order-descriptions',
  templateUrl: './order-descriptions.component.html',
  styleUrls: ['./order-descriptions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    OrderVariantComponent,
    OrderDescriptionComponent,
    SaveStripeComponent,
    MatButtonModule,
    TranslocoPipe,
    AsyncPipe,
  ],
})
export class OrderDescriptionsComponent {
  data = inject(MAT_BOTTOM_SHEET_DATA);
  private store = inject(Store);

  hasVariants = false;
  hasSelectedVariants = false;
  childComponent = OrderDescriptionComponent;

  autocomplete$ = this.store.pipe(select(selectAutocompleteSearch));

  constructor() {
    const data = this.data;

    if (data.orders || data.showOnlyVariants) {
      this.hasVariants = data.orders.some(
        (order: Order) => order.all_variants?.length > 0,
      );
    }
  }

  saveData(): void {
    if (
      this.hasSelectedVariants ||
      !this.hasVariants ||
      this.data.showOnlyVariants
    ) {
      this.data.closeDescription();
    } else {
      this.hasSelectedVariants = true;
    }
  }

  descriptionChanged(
    order: Order,
    {
      description,
      quantity,
      portion_size,
      consistency,
      texture,
      variants,
    }: Partial<{
      description: string;
      quantity: number;
      portion_size: number;
      consistency: Consistency;
      texture: Texture;
      variants: OrderVariant[];
    }>,
  ): void {
    order.description = description;
    order.quantity = quantity ?? this.data.quantityFieldIncrement;
    order.portion_size = portion_size ?? 1;
    order.consistency = consistency;
    order.texture = texture;
    order.variants = variants ?? [];
  }

  variantsSelected(order: Order, variants: OrderVariant[]): void {
    order.variants = variants;
  }
}
