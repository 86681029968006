import { DestroyRef, Injectable, NgZone, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  from,
  fromEvent,
  interval,
  merge,
  Observable,
  of,
  Subject,
  Subscription,
  timer,
} from 'rxjs';
import {
  bufferTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  scan,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  private _ngZone = inject(NgZone);
  private destroyRef = inject(DestroyRef);

  ping$: Observable<any>;

  /**
   * Events that can interrupts user's inactivity timer.
   */
  protected activityEvents$: Observable<any>;

  protected timerStart$ = new Subject<boolean>();
  protected idleDetected$ = new Subject<boolean>();
  protected timeout$ = new Subject<boolean>();
  protected idle$: Observable<any>;
  protected timer$: Observable<any>;
  /**
   * Idle value in milliseconds.
   * Default equals to 10 minutes.
   */
  protected idleMillisec = 30 * 1000;
  /**
   * Idle buffer wait time milliseconds to collect user action
   * Default equals to 1 Sec.
   */
  protected idleSensitivityMillisec = 1000;
  /**
   * Timeout value in seconds.
   * Default equals to 5 minutes.
   */
  protected timeout = 30;
  /**
   * Ping value in milliseconds.
   * Default equals to 2 minutes.
   */
  protected pingMillisec = 10 * 1000;
  /**
   * Timeout status.
   */
  protected isTimeout: boolean;
  /**
   * Timer of user's inactivity is in progress.
   */
  protected isInactivityTimer: boolean;
  protected isIdleDetected: boolean;

  protected idleSubscription: Subscription;

  /**
   * Start watching for user idle and setup timer and ping.
   */
  startWatching() {
    if (!this.activityEvents$) {
      this.activityEvents$ = merge(
        fromEvent(window, 'mousemove'),
        fromEvent(window, 'resize'),
        fromEvent(document, 'keydown'),
      );
    }

    this.idle$ = from(this.activityEvents$);

    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }

    // If any of user events is not active for idle-seconds when start timer.
    this.idleSubscription = this.idle$
      .pipe(
        bufferTime(this.idleSensitivityMillisec), // Starting point of detecting of user's inactivity
        filter(
          (arr) =>
            !arr.length && !this.isIdleDetected && !this.isInactivityTimer,
        ),
        tap(() => {
          this.isIdleDetected = true;
          this.idleDetected$.next(true);
        }),
        switchMap(() =>
          this._ngZone.runOutsideAngular(() =>
            interval(1000).pipe(
              takeUntil(
                merge(
                  this.activityEvents$,
                  timer(this.idleMillisec).pipe(
                    tap(() => {
                      this.isInactivityTimer = true;
                      this.timerStart$.next(true);
                    }),
                  ),
                ),
              ),
              finalize(() => {
                this.isIdleDetected = false;
                this.idleDetected$.next(false);
              }),
            ),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    this.setupTimer(this.timeout);
    this.setupPing(this.pingMillisec);
  }

  stopWatching() {
    this.stopTimer();
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }
  }

  stopTimer() {
    this.isInactivityTimer = false;
    this.timerStart$.next(false);
  }

  resetTimer() {
    this.stopTimer();
    this.isTimeout = false;
  }

  /**
   * Return observable for timer's countdown number that emits after idle.
   */
  onTimerStart(): Observable<number> {
    return this.timerStart$.pipe(
      distinctUntilChanged(),
      switchMap((start) => (start ? this.timer$ : of(null))),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  /**
   * Return observable for idle status changed
   */
  onIdleStatusChanged(): Observable<boolean> {
    return this.idleDetected$
      .asObservable()
      .pipe(takeUntilDestroyed(this.destroyRef));
  }

  /**
   * Return observable for timeout is fired.
   */
  onTimeout(): Observable<boolean> {
    return this.timeout$.pipe(
      filter((timeout) => !!timeout),
      tap(() => (this.isTimeout = true)),
      map(() => true),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  // getConfigValue(): UserIdleConfig {
  //   return {
  //     idle: this.idleMillisec,
  //     idleSensitivity: this.idleSensitivityMillisec,
  //     timeout: this.timeout,
  //     ping: this.pingMillisec
  //   };
  // }

  /**
   * Set config values.
   * @param config
   */
  // setConfigValues(config: UserIdleConfig) {
  //   if (this.idleSubscription && !this.idleSubscription.closed) {
  //     console.error('Call stopWatching() before set config values');
  //     return;
  //   }

  //   this.setConfig(config);
  // }

  // private setConfig(config: UserIdleConfig) {
  //   if (config.idle) {
  //     this.idleMillisec = config.idle * 1000;
  //   }
  //   if (config.ping) {
  //     this.pingMillisec = config.ping * 1000;
  //   }
  //   if (config.idleSensitivity) {
  //     this.idleSensitivityMillisec = config.idleSensitivity * 1000;
  //   }
  //   if (config.timeout) {
  //     this.timeout = config.timeout;
  //   }
  // }

  /**
   * Set custom activity events
   *
   * @param customEvents Example: merge(
   *   fromEvent(window, 'mousemove'),
   *   fromEvent(window, 'resize'),
   *   fromEvent(document, 'keydown'),
   *   fromEvent(document, 'touchstart'),
   *   fromEvent(document, 'touchend')
   * )
   */
  setCustomActivityEvents(customEvents: Observable<any>) {
    if (this.idleSubscription && !this.idleSubscription.closed) {
      console.error('Call stopWatching() before set custom activity events');
      return;
    }

    this.activityEvents$ = customEvents;
  }

  /**
   * Setup timer.
   *
   * Counts every seconds and return n+1 and fire timeout for last count.
   * @param timeout Timeout in seconds.
   */
  protected setupTimer(timeout: number) {
    this._ngZone.runOutsideAngular(() => {
      this.timer$ = interval(1000).pipe(
        take(timeout),
        map(() => 1),
        scan((acc, n) => acc + n),
        tap((count) => {
          if (count === timeout) {
            this.timeout$.next(true);
          }
        }),
        map((count) => this.timeout - count),
        takeUntilDestroyed(this.destroyRef),
      );
    });
  }

  /**
   * Setup ping.
   *
   * Pings every ping-seconds only if is not timeout.
   * @param pingMillisec
   */
  protected setupPing(pingMillisec: number) {
    this.ping$ = interval(pingMillisec).pipe(filter(() => !this.isTimeout));
  }
}
