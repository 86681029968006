import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterAction } from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import {
  catchError,
  filter,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { fetchRepeatOrders } from 'src/app/redux/orders/orders.actions';
import {
  selectQueryParams,
  selectRouteParams,
} from 'src/app/redux/router/router.selectors';
import {
  ManagableAccess,
  isManagableItem,
} from 'src/app/shared/models/consumers';

import {
  getFormattedDate,
  getMenusParamsFromQueryParams,
  getParamsFromManagableItem,
  isObjectEmpty,
  removeUndefinedAndNull,
} from '../../shared/utils.functions';
import { selectOrdersRouterExtras } from '../orders/orders.selectors';
import { selectSimpleConsumer, selectUser } from '../user/user.selectors';
import { MenusServiceBuilder } from '../../shared/services/menus/menus.builder';
import { UtilsService } from '../../shared/services/utils.service';
import {
  handleHttpError,
  showSnackbarMessage,
} from './../global/global.actions';
import { clearOrdersPage, setManagableItem } from './../orders/orders.actions';
import {
  fetchMenu,
  fetchMenus,
  menuSelected,
  patchCheckoutDate,
  redirectToOrders,
  setCurrentMenu,
  setCurrentMenuAndRedirect,
  setMenus,
  setSelectedDate,
} from './menus.actions';
import { selectCurrentMenu, selectPickedDate } from './menus.selectors';

@Injectable()
export class MenusEffects {
  private actions$ = inject(Actions);
  private activatedRoute = inject(ActivatedRoute);
  private menusServiceBuilder = inject(MenusServiceBuilder);
  private store = inject(Store);
  private router = inject(Router);
  private transloco = inject(TranslocoService);
  private utilsService = inject(UtilsService);

  onNavigatedToMenusPage = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      filter(
        (action: RouterAction<RouterStateSnapshot>) =>
          action?.payload?.event?.url === `/menus`,
      ),
      switchMap(() => [clearOrdersPage()]),
      catchError(() => EMPTY),
    ),
  );

  menuSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(menuSelected),
      switchMap(({ menu, item, date }) => [
        setSelectedDate({ date }),
        setCurrentMenuAndRedirect({
          menu,
          item,
          date,
        }),
      ]),
      catchError(() => EMPTY),
    ),
  );

  setCurrentMenuAndRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCurrentMenuAndRedirect),
      switchMap(({ menu, item, date }) => {
        // dated menus have the has_orders boolean which indicates if orders exist for this menu/date
        // dateless menus have a list_orders array which contains the dates for which orders exist for this menu
        const complete =
          menu?.show_date || !date
            ? menu?.has_orders
            : menu?.list_orders.includes(date);
        return [
          setCurrentMenu({ menu, date }),
          setManagableItem({ item }),
          redirectToOrders({ complete }),
        ];
      }),
      catchError(() => EMPTY),
    ),
  );

  redirectToOrders$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(redirectToOrders),
        withLatestFrom(
          this.store.select(selectCurrentMenu),
          this.store.select(selectRouteParams),
          this.store.select(selectOrdersRouterExtras),
          this.store.select(selectQueryParams),
          this.store.select(selectPickedDate),
          this.store.select(selectSimpleConsumer),
        ),
        switchMap(
          ([
            { complete },
            currentMenu,
            params,
            item,
            queryParams,
            pickedDate,
            simpleConsumer,
          ]) => {
            const menuId =
              currentMenu?.identifier || (params?.menuId as string);
            let date =
              currentMenu?.date || (params?.date as string) || pickedDate;
            if (!date) {
              console.error(`Date isn't set, using today's date`);
              date = getFormattedDate();
            }
            const navigate = [`orders`, menuId, date];
            const extraData = { state: item, queryParams: {} };
            if (
              complete ||
              (complete === undefined &&
                currentMenu?.list_orders?.includes(date))
            ) {
              navigate.push(`complete`);
            }
            if (item && isManagableItem(item)) {
              Object.assign(extraData, {
                queryParams: { ...getParamsFromManagableItem(item) },
              });
            } else if (queryParams && !isObjectEmpty(queryParams)) {
              Object.assign(extraData.queryParams, { ...queryParams });
            }
            if (extraData?.queryParams && queryParams?.back) {
              extraData.queryParams[`back`] = queryParams.back as string;
            }
            if (simpleConsumer) {
              extraData['queryParamsHandling'] = 'preserve';
            }
            this.router.navigate(navigate, extraData);
            return EMPTY;
          },
        ),
      ),
    { dispatch: false },
  );

  fetchMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchMenu),
      switchMap(
        ({
          params: { menu, date, created_by, consumer },
          fetchRepeatOrders: repeat,
        }) =>
          this.menusServiceBuilder
            .getService()
            .fetchMenu({
              menu,
              date,
              ...removeUndefinedAndNull({ created_by, consumer }),
            })
            .pipe(
              mergeMap((menus) => [
                ...(menus?.results?.length && menus?.results?.[0].date
                  ? []
                  : [setSelectedDate({ date })]),
                setCurrentMenu({ menu: menus?.results?.[0] }),
                ...(repeat || menus?.results?.[0]?.repeated_orders_consumers
                  ? [
                      fetchRepeatOrders({
                        menu_name:
                          menus?.results?.length &&
                          (menus?.results?.[0].type ||
                            !menus?.results?.[0].date)
                            ? menus?.results?.[0].name
                            : '',
                        created_by,
                        consumer,
                        manage: true,
                      }),
                    ]
                  : []),
              ]),
              catchError((error: unknown) => [handleHttpError({ error })]),
            ),
      ),
    ),
  );

  fetchMenus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchMenus),
      withLatestFrom(this.store.pipe(select(selectUser))),
      switchMap(([{ params }, user]) =>
        this.menusServiceBuilder
          .getService()
          .fetchMenus(params)
          .pipe(
            mergeMap((menus) => {
              const newMenus = menus?.results?.map((menu) => {
                if (menu?.date && user?.status?.orderby_days_offset !== null) {
                  const menuDate = new Date(menu.date);
                  const dateToCompare = new Date();
                  menuDate.setDate(
                    menuDate.getDate() - user?.status?.orderby_days_offset,
                  );
                  const showBadge =
                    menuDate.toDateString() === dateToCompare.toDateString();

                  menu.showBadge =
                    showBadge &&
                    ((menu.order_by_time && {
                      time: menu.order_by_time.substring(
                        0,
                        menu.order_by_time.length - 3,
                      ),
                    }) || {
                      time: user?.status?.orderby_time.substring(
                        0,
                        user?.status?.orderby_time.length - 3,
                      ),
                    });
                }
                return menu;
              });
              return [setMenus({ menus: newMenus })];
            }),
            catchError((error: unknown) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  patchCheckoutDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patchCheckoutDate),
      switchMap(({ payload: { data, url } }) =>
        this.utilsService
          .patch<Partial<ManagableAccess>, ManagableAccess>(url, data, {
            manage: true,
          })
          .pipe(
            mergeMap((result) => {
              const params = getMenusParamsFromQueryParams(
                this.activatedRoute.snapshot.queryParamMap,
              );
              return [
                showSnackbarMessage({
                  message: this.transloco.translate(this.MODIFICATION_SAVED),
                  button: this.transloco.translate(this.GOT_IT),
                }),
                setManagableItem({ item: result || undefined }),
                fetchMenus({
                  params: isObjectEmpty(params) ? { own: true } : params,
                }),
              ];
            }),
            catchError((error: unknown) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  readonly GOT_IT = 'manage-accesses.invite-user.got-it';
  readonly MODIFICATION_SAVED =
    'manage-accesses.invite-user.modification-saved';
}
